import React from 'react'
import { Avatar } from 'antd'
const Organizer = ({ name, title, color, shape }) => {
    return (
        <div className="Organizer gx-d-flex gx-flex-row">
            <Avatar shape={shape} className="gx-mr-2" style={{ backgroundColor: color ? color : '#ADB1C9' }} size="large">
                {name && name.charAt(0) + name.charAt(1)}
            </Avatar>
            <div className="desc">

                <h3 className="gx-mb-0">{name}</h3>
                <p>{title}</p>
            </div>
        </div>

    )
}

export default Organizer

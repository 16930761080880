
const INIT_STATE = {
    availableBalance: 0,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'recentBalance_SUCCESS': {
            const Record = action.payload.Record;
            return { ...state, availableBalance: Record[0]?Record[0].balance:0 }
        }

        default:
            return state;
    }
}
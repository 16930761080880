import React, {useEffect} from 'react'
import { Row, Col } from 'antd';
import Nav from 'components/Navigation'
import Footer from 'components/Footer'
import DonationBox from 'containers/Donation'
import CareCenterMain from 'containers/CareCenterMain'
import InfoSection from 'containers/InfoSection'
import CommentSection from 'containers/CommentSection'
import SideBarCard from 'containers/SideBarCard';
import Slider from 'containers/Slider'
import { useDispatch, useSelector } from 'react-redux';
import { DataGetAction } from 'redux/actions/http'
import { FacebookShareButton, TwitterShareButton, EmailShareButton } from "react-share";
import { TwitterSquareFilled, FacebookFilled, MailFilled  } from '@ant-design/icons'
import {  useLocation } from 'react-router-dom'

const Index = () => {

    const dispatch = useDispatch();
    const ResProps = useSelector(state => state.user.publicProfile);
    const location = useLocation();
    const id = location.state && location.state.data;
       
    
    const fetchPublicProfile = () => {
        dispatch(DataGetAction('getPublicProfile',{ query: "Rh", id }));
        window.scrollTo(0,0);
    }

    useEffect(fetchPublicProfile, [id]);

    
    
    return (
        <Row>
            <Col xl={24} md={24} sm={24} xs={24}>
                <Nav />
            </Col>
            <Col className="bannerSection" style={{paddingTop:'70px'}} xl={24} md={24} sm={24} xs={24}>
                <div className="MainSection">
                    <Row className=" gx-p-0 gx-m-0 ">
                        <Col xl={24} md={24} sm={24} xs={24} className="gx-m-0 gx-p-0">
                            <CareCenterMain ResProps={ResProps}/>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col xl={24} md={24} sm={24} xs={24} className="whiteBackground">
                <div className="gx-container gx-pl-0 gx-mt-5 gx-pb-5">
                    <Row className="gx-mr-2 gx-ml-0">
                        <Col xl={18} md={18} sm={24} xs={24} className="gx-pr-3">
                            <InfoSection title="About" desc={ResProps?ResProps.about:""} />

                            <CommentSection title="Comments" />

                            <InfoSection title="Updates" desc={ResProps?ResProps.updates:""} />

                            <div className="helpSpread">
                                <h2>Help spread the world</h2>
                                <div className="SharedButton">
                                    <FacebookShareButton url="https://ub4i.digitalsensei.net/careCenter" >
                                        <span className="shareButton facebook">
                                            <FacebookFilled className="socialFooter" />
                                            Share it!
                                        </span>
                                    </FacebookShareButton>
                                    <TwitterShareButton url="https://ub4i.digitalsensei.net/careCenter" >
                                        <span className="shareButton twitter">
                                            <TwitterSquareFilled className="socialFooter" />
                                            Tweet!
                                        </span>
                                    </TwitterShareButton>
                                    <EmailShareButton url="https://ub4i.digitalsensei.net/careCenter" >
                                        <span className="shareButton email">
                                            <MailFilled className="socialFooter" />
                                            Email!
                                        </span>
                                    </EmailShareButton>

                                </div>
                            </div>
                        </Col>
                        <Col xl={6} md={6} sm={24} xs={24}>
                            <div className="sideBarDonationBox gx-mt-5">
                                <DonationBox showDonation={true} id={id} page='careCenter'/>
                            </div>
                            <SideBarCard title="Care centers donating to" data={[]} />

                        </Col>
                    </Row>
                </div>
            </Col>
            <Col xl={24} md={24} sm={24} xs={24} className="sliderContainer">
                <div className="gx-container gx-p-0">
                    <Slider title="Other Care Center" data={[]} />
                </div>

            </Col>


            <Col xl={24} md={24} sm={24} xs={24} className="footerContainer">
                <Footer />
            </Col>
        </Row>
    )
}

export default Index
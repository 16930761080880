import React, { useState } from "react";
import Widget from "components/Widget";
import { useDispatch } from 'react-redux';
import { DataRequestAction } from 'redux/actions/http'
import { Form, Button, Input } from "antd";



const Organizer = ({ keyObj, title, data, id }) => {
    const dispatch = useDispatch();
    const [stateAction, setActionButton] = useState("cancel");
    const [form] = Form.useForm();

    const changeButton = (type) => {
        setActionButton(type);
    }

    const updateProfile = () => {
        form.validateFields().then(values => {
            dispatch(DataRequestAction('POST', 'updateProfile', { _id: id, [`${keyObj}`]: values }, '', 'profile', false));
            changeButton("edit")
        })
    }

    let actionButton = null;
    if (stateAction === "action") {
        actionButton = <><Button className="gx-pt-0" onClick={() => { changeButton("cancel") }} type="info">Cancel</Button><Button onClick={() => { updateProfile() }} className=" gx-pt-0" type="primary">Save</Button></>;
    } else {
        actionButton = <Button className="gx-pt-0" type="primary" onClick={() => { changeButton("action") }}>Edit</Button>;

    }

    return (
        <Widget title={title} extra={actionButton} styleName="gx-card-tabs gx-card-tabs-right gx-card-profile">

            {stateAction !== "action" && <div className="OrganizerValue gx-mt-3">
                <p><i className="icon icon-contacts"></i> {data ? data.name : "Organizer Name"}</p>
                <p><i className="icon icon-phone"></i> {data ? data.phoneNumber : "Phone Number"}</p>
                <p><i className="icon icon-email"></i> {data ? data.email : "Email"}</p>
            </div>}
            {stateAction === "action" && <div className="OrganizerValue">
                <Form
                    form={form}
                    name="normal_login"
                    className="login-form"
                    initialValues={data}
                >
                    <Form.Item
                        name="name"
                        rules={[{ required: true, message: 'Please input your name!' }]}
                    >
                        <Input placeholder="Organizer Name" />
                    </Form.Item>
                    <Form.Item
                        name="phoneNumber"
                        rules={[{ required: true, message: 'Please input your phone Number!' }]}
                    >
                        <Input placeholder="Phone Number" />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        rules={[{ type: 'email', message: "Email is not valid" }, { required: true, message: 'Please input your Email!' }]}
                    >
                        <Input placeholder="Organizer Email" />
                    </Form.Item>

                </Form>
            </div>}


        </Widget>
    );
}


export default Organizer;

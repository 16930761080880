import _ from 'lodash';
import jwtDecoder from 'jwt-decode'
const INIT_STATE = {
    LoginVisible: false,
    modalVisible: false,
    selectedItem: null,
    orderLoader: false,
    error: false,
    success: false,
    orderCount: 0,
    Orders: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'getOrder_SUCCESS': {
            console.log("=========== orders =========", action.payload.Record);
            return { ...state, Orders: action.payload.Record };
        }
        case 'editOrder_SUCCESS': {
            console.log("============== editOrder socket=========", action);
            let oldOrder = state.Orders;
            _.map(oldOrder, x => {
                if (x._id === action.payload.Record._id) {
                    x.status = action.payload.Record.status;
                }
            });
            const newOrder = [...oldOrder];
            return { ...state, Orders: newOrder, modalVisible: false };
        }


        case 'newOrderIsCreated_SUCCESS': {
            const decoded = jwtDecoder(localStorage.getItem('token'));
            const oldOrders = state.Orders;
            let newOrderObj = oldOrders;
            let count = 0;
            if (decoded.role === "superAdmin") {
                newOrderObj = [...oldOrders, action.payload]
                count = state.orderCount + 1
            }
            if (decoded.role === "restaurant" && decoded._id === action.payload.createdRp) {
                newOrderObj = [...oldOrders, action.payload,]
            }
            if (decoded.role === "careCenter" && decoded._id === action.payload.createdHp) {
                newOrderObj = [...oldOrders, action.payload]
            }

            return { ...state, Orders: newOrderObj, orderCount: count }

        }


        case 'show_order_detail_modal': {
            return { ...state, modalVisible: !state.modalVisible, selectedItem: action.payload };
        }
        default:
            return state;
    }
}
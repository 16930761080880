import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Radio, Spin, Select, message } from 'antd';
import { SimpleModalAction } from 'redux/actions/Common';
import { DataRequestAction, updateAllState, DataGetAction } from 'redux/actions/http';
import { useDispatch, useSelector } from 'react-redux'
import restaurantImg from 'assets/signup/fast-food.svg'
import hospitalImg from 'assets/signup/hospital.svg'

const Index = () => {
    const [form] = Form.useForm();
    const [selectedRole, setSelectedRole] = useState('Restaurant');
    const authProps = useSelector(state => state.auth);
    const categoryProps = useSelector(state => state.commonR.RestaurantCategory);


    const dispatch = useDispatch();

    const OpenSignInModal = () => {
        dispatch(updateAllState('auth'))
        dispatch(SimpleModalAction('signUp', false))
        dispatch(SimpleModalAction('login', true))
    }

    const LoadPage = () => {
        dispatch(DataGetAction('getCategories', ""));
        return () => {
            dispatch(updateAllState('auth'));
        }
    }
    useEffect(LoadPage, [])



    const onFinish = values => {
        values.role = values.layout
        if (values.role === "restaurant") {
            values.tags = ['Restaurant'];
        }else{
            values.tags = ['CareCenter'];
        }

        dispatch(DataRequestAction('POST', 'signup', values, '', 'signup', false));
    };

    let options = [];
    if (categoryProps) {
        categoryProps.map(Item => {
            if (Item !== "All") {
                options.push({ key: Item, value: Item })
            }
            return Item;
        })
    }

    if(authProps.success){
        form.resetFields();
        message.success("Your signup request is submitted successfully for admin review and consideration.");
        dispatch(updateAllState('auth'));
        dispatch(SimpleModalAction('signUp', false))
      
    }


    return (
        <div className="SignIn gx-p-5">

            <div className="welcomeMessage gx-text-center gx-mb-5">
                <h2 className="gx-font-weight-bold gx-mb-0">Getting started</h2>
                <p>Register your restaurant or hospital</p>
            </div>

            <Form
                form={form}
                name="register"
                onFinish={onFinish}
                scrollToFirstErrorZ
                initialValues={{ 'layout': 'restaurant' }}
            >

                <Form.Item name="layout" className="SignUpRadio">
                    <Radio.Group onChange={(e) => { e.target.value === 'careCenter' ? setSelectedRole('Care Center') : setSelectedRole('Restaurant') }}>
                        <Radio.Button value="restaurant" defaultChecked={true}> <img src={restaurantImg} alt="" /> Restaurant</Radio.Button>
                        <Radio.Button value="careCenter"> <img src={hospitalImg} alt="" /> Care Center</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="name"
                    rules={[{ required: true, message: `Please enter ${selectedRole} Name!`, whitespace: true }]}
                >
                    <Input placeholder={`Name of ${selectedRole}`} />
                </Form.Item>

                <Form.Item
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                        },
                        {
                            required: true,
                            message: 'Please enter  E-mail!',
                        },
                    ]}
                >
                    <Input placeholder="Please Enter the email" />
                </Form.Item>


                <Form.Item
                    name="phoneNumber"
                    rules={[{ required: true, message: 'Please enter phone number!' }]}
                >
                    <Input style={{ width: '100%' }} placeholder="Phone Number" />
                </Form.Item>
                {selectedRole === "Restaurant" && < Form.Item
                    name="foodType"
                    rules={[{ required: true, message: 'Please select at least one type!' }]}
                >
                    <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        placeholder="Please select food type you provide"
                        options={options}
                    />

                </Form.Item>}


                <Form.Item
                    name="location"
                    rules={[{ required: true, message: `Please ${selectedRole} location!`, whitespace: true }]}
                >
                    <Input placeholder={`${selectedRole} location`} />
                </Form.Item>

                <Form.Item
                    name="password"

                    rules={[
                        {
                            required: true,
                            message: 'Please enter your password!',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password placeholder="Password" />
                </Form.Item>

                <Form.Item
                    name="confirm"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'password not match!',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('The two passwords that you entered do not match!');
                            },
                        }),
                    ]}
                >
                    <Input.Password placeholder="Confirm Password" />
                </Form.Item>


                <Form.Item>
                    {authProps.ShowSignUpLoader && <Spin><Button type="primary" htmlType="button" className="login-form-button gx-mt-4 gx-d-flex gx-w-100 gx-flex-row gx-justify-content-center" style={{ lineHeight: '0px' }}>
                        Register
                    </Button></Spin>}
                    {!authProps.ShowSignUpLoader && <Button type="primary" htmlType="submit" className="login-form-button gx-mt-4 gx-d-flex gx-w-100 gx-flex-row gx-justify-content-center" style={{ lineHeight: '0px' }}>
                        Register
                    </Button>}
                </Form.Item>
                <p className={`gx-text-center ${authProps.error ? "error" : authProps.success ? "success" : ""}`}> {authProps.message}</p>

                <p className="gx-text-center" onClick={() => OpenSignInModal()}> Already have an account? <span className="gx-pointer gx-link">Login</span></p>


            </Form>
        </div >
    )
}

export default Index

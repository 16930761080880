import React from "react";
import { EditOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux';
import { DataEntryModalAction } from 'redux/actions/Common'
import ProfileModal from './ProfileModal'
import Tags from 'components/Tags/Display'
const ProfileHeader = ({ profileInfo }) => {
  const dispatch = useDispatch();
  const modalType = "show_profile_modal";

  const editProfile = () => {
    dispatch(DataEntryModalAction(modalType, null));
  }

  console.log("============ Profile =========", profileInfo);
  const color = ["purple", "geekblue", "blue", "red", "yellow", "magenta"];

  const renderTags = () => {
    const data = profileInfo && profileInfo.tags;
    if (data) {
      return data.map((Item, index) => <Tags color={color[index]} title={Item} />);
    }
  }
  return (
    <div className="gx-profile-banner">
      <div className="gx-profile-container">
        <div className="gx-profile-banner-top">
          <div className="gx-profile-banner-top-left">
            <div className="gx-profile-banner-avatar-info">
              <h2 className="gx-mb-0 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">{profileInfo && profileInfo.name} <span className="gx-pointer" onClick={() => editProfile()}><EditOutlined style={{ color: '#FAB005', fontSize: '19px', marginLeft: '6px' }} /></span></h2>
              <p className="gx-mb-2">{renderTags()}</p>
              <p>{profileInfo && profileInfo.situation}</p>
            </div>
          </div>
          <div className="gx-profile-banner-top-right">
          </div>
        </div>
        <div className="gx-profile-banner-bottom">
          <div className="gx-tab-list">
            <ul className="gx-navbar-nav">
              <li>
                <span className="gx-link">{profileInfo && profileInfo.location}</span>
              </li>

            </ul>
          </div>
        </div>
      </div>
      <ProfileModal type={modalType} title="Basic info" initialValues={profileInfo} />
    </div>
  )
};

export default ProfileHeader;

import React from 'react';
import { Modal, Form, Input, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DataEntryModalAction } from 'redux/actions/Common'
import { DataRequestAction, updateAllState } from 'redux/actions/http'
import alerts from 'alerts'

interface Values {
  title: string;
  description: string;
  modifier: string;
}

interface CollectionCreateFormProps {
  visible: boolean;
  onCreate: (values: Values) => void;
  onCancel: () => void;
}

const CollectionCreateForm: React.FC<CollectionCreateFormProps> = ({
  visible,
  initialValues,
  loader,
  title,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      title={`${initialValues === null ? `Add` : 'Edit'} ${title}`}
      className="formModal"
      okText={`${initialValues === null ? 'Add' : 'Update'}`}
      cancelText="Cancel"
      confirmLoading={loader}
      onCancel={()=>{ onCancel()}}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            form.resetFields();
            onCreate(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{title:initialValues && initialValues.title, description:initialValues && initialValues.description}}
      >
        <Form.Item
          name="title"
          label="Name"
          defaultValue={initialValues && initialValues.title}
          rules={[{ required: true, message: 'Please input the title of collection!' }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const CategoryForm = ({ type, title }) => {

  const dispatch = useDispatch();
  const categoryProps = useSelector(state => state.resCategory)
  const onCreate = values => {

    if (categoryProps.selectedItem === null) {
      dispatch(DataRequestAction('POST', 'addResCategory', values, '', 'category', false));
    } else {
      values._id = categoryProps.selectedItem._id;
      dispatch(DataRequestAction('POST', 'editResCategory', values, '', 'category', false));
    }


  };

  if (categoryProps.success) {

    message.success(alerts.record_updated_success);
    dispatch(updateAllState('category'));
  }

  return <CollectionCreateForm
    visible={categoryProps.modalVisible}
    initialValues={categoryProps.selectedItem}
    loader={categoryProps.categoryLoader}
    title={title}
    onCreate={onCreate}
    onCancel={() => dispatch(DataEntryModalAction(type, null))}
  />

};


export default CategoryForm;
import React, { useEffect } from 'react'
import { Row, Col } from 'antd'
import Layout from 'components/LayoutForPortal'
import ProfileHeader from "components/profile/ProfileHeader/index";
import About from "components/profile/About/index";
import Organizer from "components/profile/Organizer/index";
import { useDispatch, useSelector } from 'react-redux'
import { DataGetAction } from 'redux/actions/http'
import FrontPageImage from 'components/FrontImage'
import HeaderImage from 'components/HeaderImage'
import { message } from 'antd'
import alerts from 'alerts'
const FrontPage = () => {

    const dispatch = useDispatch();
    const profileProps = useSelector(state => state.profile);

   
    const fetchProfile = () => {
        dispatch(DataGetAction('getProfileInfo', ''));
    }
    useEffect(fetchProfile, []);

    if (profileProps.success) {
        message.success(alerts.record_updated_success)
    }

    const profileInfo = profileProps.ProfileInfo && profileProps.ProfileInfo[0];
    return (
        <Layout>
            <ProfileHeader profileInfo={profileInfo && profileInfo} />
            <div className="gx-profile-content">
                <Row>
                    <Col xl={14} lg={12} md={12} sm={24} xs={24}>
                        <About keyObj="about" title="About" desc={profileInfo && profileInfo.about} id={profileInfo && profileInfo._id} />
                    </Col>
                    <Col xl={10} lg={12} md={12} sm={24} xs={24}>
                        <About keyObj="updates" title="Update" desc={profileInfo && profileInfo.updates} id={profileInfo && profileInfo._id} />
                    </Col>
                </Row>
                <Row>
                    <Col xl={16} lg={14} md={14} sm={24} xs={24}>
                        <Row>
                            <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                                <FrontPageImage />
                            </Col>
                            <Col xl={16} lg={16} md={16} sm={24} xs={24}>
                                <HeaderImage />
                            </Col>
                        </Row>


                    </Col>
                    <Col xl={8} lg={10} md={10} sm={24} xs={24}>
                        <Organizer keyObj="organizer" title="Organizer" data={profileInfo && profileInfo.organizer} id={profileInfo && profileInfo._id} />
                    </Col>
                </Row>
            </div>

        </Layout>
    )
}

export default FrontPage

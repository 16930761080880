import React, { useEffect } from 'react'
import { Card } from "antd";
import { useDispatch, useSelector } from 'react-redux'
import { DataGetAction } from 'redux/actions/http'
import Layout from 'components/LayoutForPortal'
import TransactionList from 'components/Table/BasicTable'
const Transactions = () => {

    const columns = [
       
        {
            title: 'Date',
            dataIndex: 'createdAt',
        },
        {
            title: 'Order',
            dataIndex: 'OrderTrackingId',
        },
        {
            title: 'Transaction',
            dataIndex: 'TransactionTrackingId',
        },
        {
            title: 'Sender',
            dataIndex: 'sender',
        },
        {
            title: 'Amount',
            dataIndex: 'phone',
        },
        {
            title: 'Payment method',
            dataIndex: 'status',
        }
   ];

    const dispatch = useDispatch();
    const TransProps = useSelector(state => state.transaction);
 
 
    const fetchTransaction = () => {
        dispatch(DataGetAction('getTransaction', { query: "all" }));
    }
    useEffect(fetchTransaction, [])

    return (
        <Layout>
            <Card title={<h2>Transactions</h2>}>
                <TransactionList columns={columns} data={TransProps.Transactions} />
            </Card>
           
        </Layout>
    )
}

export default Transactions

import React from 'react'
import { Row, Col } from 'antd'
import Organizer from 'components/Organizer'
import R1 from 'assets/restaurant/R1.png'
import R2 from 'assets/restaurant/R1.png'
import LightBox from 'components/lightBox'
import { useDispatch } from 'react-redux';

const Index = (props) => {
    const { businessType, location, situation, tags, headerImages, organizer, headerVideoUrl = "https://www.youtube.com/embed/c21QZnQtGqo" } = props.ResProps ? props.ResProps : "";
    const color = ["purple", "orange", "blue", "red", "yellow", "magenta"];
    const dispatch = useDispatch();

    const openLightBox = () => {

        let data = []

        headerImages.map(x => {
            let obj = { url: x, type: 'photo', altTag: 'placeholder image' }
            data.push(obj);
            return x;
        })
        let videoUrl = { url: headerVideoUrl, type: 'video', altTag: 'placeholder video' };
        data.push(videoUrl);

        dispatch({
            type: "show_light_box_SUCCESS",
            payload: data
        })
    }


    return (
        <>
            <Row className="gx-mr-3">
                <Col xl={24} md={24} sm={24} xs={24} className="gx-px-0">
                    <div className="topRow gx-mb-2 gx-d-flex gx-flex-row gx-justify-content-between gx-align-items-center">
                        <div className="location gx-d-flex gx-flex-row gx-justify-content-between gx-align-items-center">
                            <h4 className="gx-mr-3 gx-d-flex"><i className="icon icon-company gx-mr-2" style={{ color: '#22343D' }}></i> {businessType}</h4>
                            <h4 className="gx-d-flex"><i className="icon icon-geo-location gx-mr-2" style={{ color: '#22343D' }}></i>{location}</h4>
                        </div>
                        <div className="tags">
                            {tags && tags.map((Item, index) => <span className="gx-mr-2" style={{
                                width: '63px',
                                height: '24px',
                                background: ` 0% 0 % no - repeat padding- box`,
                                backgroundColor: color[index],
                                borderRadius: '6px',
                                padding: '5px 15px',
                                color: '#fff'
                            }}>{Item}</span>)}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="gx-mr-2 RestaurantPromo">
                <Col xl={18} md={18} sm={24} xs={24} className="gx-pr-0" style={{ marginTop: '8px' }}>
                    <div>
                        <iframe title="as" className="video"
                            src={headerVideoUrl}>
                        </iframe>
                    </div>
                </Col>
                <Col xl={6} md={6} sm={24} xs={24} className="gx-p-0">
                    <div>
                        <img className="gx-pointer" src={headerImages && headerImages[0] ? headerImages[0] : R1} alt="" height={177} width="100%" onClick={() => openLightBox()} />
                        <img className="gx-pointer" src={headerImages && headerImages[1] ? headerImages[1] : R2} alt="" height={177} width="100%" onClick={() => openLightBox()} />
                    </div>
                </Col>
            </Row>
            <Row className="gx-mt-3">
                <Col xl={24} md={24} sm={24} xs={24}>
                    <p>{situation}</p>
                </Col>
            </Row>
            <Row className="gx-mr-3 ">
                <Col xl={24} md={24} sm={24} xs={24}>
                    <div className="organizerContainer gx-d-flex gx-flex-row gx-justify-content-between gx-align-items-center">
                        <Organizer name={organizer ? organizer.name : "Suzy smith"} title="Organizer" />
                        <a href={`mailto: ${organizer ? organizer.email : ''}`} className="ant-btn  gx-pt-0">Contact</a>
                    </div>
                </Col>
            </Row>
            <LightBox />
        </>
    )
}

export default Index

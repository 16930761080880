import React from 'react'
import Layout from 'components/LayoutForPortal'
import { Row, Col } from 'antd'
import TotalRevenueCard from "components/Dashboard/TotalRevenueCard";
//import AvailableBalance from "components/Dashboard/AvailableBalance";
import RecentActivity from "components/Dashboard/RecentActivity";
import { recentActivity } from "./data";
import Widget from "components/Widget/index";



const Dashboard = () => {
    return (
        <Layout>
            <Row>
                <Col xl={16} lg={16} md={16} sm={24} xs={24}>
                    <Widget>
                        <RecentActivity recentList={recentActivity} shape="circle" />
                    </Widget>

                </Col>
                <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                    <TotalRevenueCard title="Donation Received From your page" symbol="$" />
                    {/* <AvailableBalance title="Available Balance" symbol="$" /> */}
                </Col>
               
            </Row>
        </Layout>
    )
}

export default Dashboard

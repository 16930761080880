import React from 'react'
import { Row, Col, Button, Avatar } from 'antd';
import Nav from 'components/Navigation'
import Footer from 'components/Footer'
import B1 from 'assets/about/Rectangle.jpg'
import B2 from 'assets/about/Restaurant.jpg'
import { UserOutlined } from '@ant-design/icons';
const Index = () => {
    return (
        <Row className="about">
            <Col xl={24} md={24} sm={24} xs={24}>
                <Nav />
            </Col>
            <Col className="bannerSection" xl={24} md={24} sm={24} xs={24} style={{background:'#fff'}}>
                <img src={B1} alt="" width="100%" />
                <div className="gx-container">
                    <div className="rectangularBox purple">
                        <p className="gx-fs-xxxl gx-text-center gx-mb-0" style={{ color: '#fff', lineHeight: '39px' }}>
                            Our mission is to help local businesses while showing support showing support to the ones risking their lives during this pandemic
                        </p>

                    </div>
                </div>
            </Col>
            <Col xl={24} md={24} sm={24} xs={24} style={{background:'#fff'}}>
                <div className="gx-container gx-mt-5 gx-pt-5 gx-f-20">
                    <p>
                        ub4i campaigns to bring communities together by showing support in one another. We believe everyone is connected in this cycle and everyone plays an important role during this pandemic. 
                    </p>
                    <p>
                    We believe in showing support and kindness for individuals, local businesses, and care workers is the best way to bring people together and make a difference. Everyone has a role to play in tough times and if we give, everyone will grow and come alive. 
                    </p>
                    <p>
                    ub4i help local businesses accept donations while showing support to the front line workers.
                  
                    </p>
                </div>
            </Col>
            <Col className="bannerSection" xl={24} md={24} sm={24} xs={24} style={{background:'#FAFAFA'}}>
                <img src={B2} alt="" width="100%" />
                <div className="gx-container">
                    <div className="rectangularBox red">
                        <p className="gx-fs-xxxl gx-text-center gx-mb-0" style={{ color: '#fff', lineHeight: '39px' }}>
                        Many restaurants will go out of business due to covid. We should come together to prevent it by supporting your favorite restaurants
                        </p>

                    </div>
                </div>
            </Col>
            <Col xl={24} md={24} sm={24} xs={24} style={{background:'#FAFAFA'}}>
                <div className="gx-container gx-text-center gx-mt-5 gx-pt-5 gx-f-20">
                    <p>We want to see good come from people and do the right thing.</p>
                    <p>We believe giving and showing kindness is the new cool.</p>
                    <p>We believe that anyone in need should be able to seek help.</p>
                    <p>We believe that giving is the key in contented life.</p>
                    <p>We believe compassion exists in every human being.</p>
                    <p>We want people to put others first.</p>
                    <p>We believe that everyone has capacity to give.</p>
                    <p>This is why we exist.</p>
                    <p> <Button type="primary" className="gx-pt-0">Let's Start Giving</Button></p>
                </div>
            </Col>
            <Col xl={24} md={24} sm={24} xs={24} style={{background:'#ffffff'}}>
                <div className="gx-container gx-text-center">
                    <h2 className="gx-mt-5 gx-mb-5 gx-font-weight-bold">Our Team</h2>
                    <ul className="outTeam gx-pl-0">
                        <li>
                            <div className="">
                            <Avatar size={80} icon={<UserOutlined />} />
                             <h2 className="gx-mt-3 gx-mb-2">Ian Wilson</h2>
                             <p>Founder</p>   
                            </div>
                        </li>
                        <li>
                            <div className="">
                            <Avatar size={80} icon={<UserOutlined />} />
                             <h2 className="gx-mt-3 gx-mb-2">Suzy Sue</h2>
                             <p>Founder</p>   
                            </div>
                        </li>
                        <li>
                            <div className="">
                            <Avatar size={80} icon={<UserOutlined />} />
                             <h2 className="gx-mt-3 gx-mb-2">Anna</h2>
                             <p>Founder</p>   
                            </div>
                        </li>
                        <li>
                            <div className="">
                            <Avatar size={80} icon={<UserOutlined />} />
                             <h2 className="gx-mt-3 gx-mb-2">Emma Swan</h2>
                             <p>Founder</p>   
                            </div>
                        </li>
                        <li>
                            <div className="">
                            <Avatar size={80} icon={<UserOutlined />} />
                             <h2 className="gx-mt-3 gx-mb-2">John David</h2>
                             <p>Founder</p>   
                            </div>
                        </li>
                    </ul>
                </div>
            </Col>
            <h2 className="gx-mb-0 gx-mt-5 gx-w-100 gx-text-center serviceInfoHeader gx-font-weight-bold">Show support and kindness and come together</h2>
                  
            <Col xl={24} md={24} sm={24} xs={24} style={{background:'#2C4BFF0A'}} className="serviceInfo">
                <div className="gx-container gx-text-center" style={{paddingTop:'180px'}}>
                    <div className="gx-d-flex gx-flex-row gx-justify-content-center gx-align-items-center">    
                        <div className="aboutBlock gx-mr-sm-0 gx-mr-md-3 gx-mb-5">
                            <h2 className="gx-mt-4 gx-font-weight-bold">Restaurants</h2>
                            <p  className="h5 gx-mt-3 gx-mb-4 gx-w-75 gx-mx-auto">Would your restaurant like to fire up the grill and delivery food to care centers</p>
                            <Button type="primary" className="gx-pt-0  gx-mx-auto">Get Started</Button>
                        </div>
                        <div className="aboutBlock gx-ml-md-3 gx-ml-sm-0 gx-mb-5">
                            <h2 className="gx-mt-4 gx-font-weight-bold">Care centers</h2>
                            <p className="h5 gx-mt-3 gx-mb-4 gx-w-75 gx-mx-auto">Find out how to get food delivered to your care center so the staff can focus on what they do best.</p>
                            <Button type="primary" className="gx-pt-0  gx-mx-auto" >Get In Touch</Button>
                        </div>
                    </div>
                </div>
            </Col>
            
            <Col xl={24} md={24} sm={24} xs={24} className="footerContainer">
                <Footer />
            </Col>
        </Row>
    )
}

export default Index


const INIT_STATE = {
    LoginVisible: false,
    SignUpVisible: false,
    ForgotPasswordVisible: false,
    SideBarCollapsed: false,
    DeleteSuccessFully: false,
    RestaurantCategory: ["All"]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'getCategories_SUCCESS': {
            const category = action.payload.Record;
            const dataCategory = [];
            category.map(Item => {
                dataCategory.push(Item.title);
                return Item;
            });
            return { ...state, RestaurantCategory: ["All", ...dataCategory] }
        }
        case 'Simple_Modal_State_login': {
            return { ...state, LoginVisible: action.payload };
        }
        case 'Simple_Modal_State_forgotPassword': {
            return { ...state, ForgotPasswordVisible: action.payload };
        }
        case 'Simple_Modal_State_signUp': {
            return { ...state, SignUpVisible: action.payload };
        }
        case 'Side_Bar_Collapse': {
            return { ...state, SideBarCollapsed: !state.SideBarCollapsed };
        }
        case 'delete_success': {
            return { ...state, DeleteSuccessFully: true };
        }

        default:
            return state;
    }
}

import React from 'react'
import { Layout } from 'antd';
import SideBar from './SideBar'
import TopNav from './TopNav'
const { Content } = Layout;



const Index = (props) => {
    return (
        <Layout style={{height:'100%'}}>
            
                <TopNav />
               
            
            <Layout>
                <SideBar />
                <Layout style={{ padding: '0 24px 24px' }}>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                            height: '100%'
                        }}
                    >
                        {props.children}
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}

export default Index

import _ from 'lodash';

const INIT_STATE = {
    modalVisible: false,
    Cart: null,
    cartQty: 0,
    OrderLoader: false,
    error: false,
    success: false,
    message: "",
    OrderPlaceSuccess: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'order_START': {
            return { ...state, OrderLoader: true };
        }

        case 'order_STOP': {
            return { ...state, OrderLoader: false };
        }

        case 'addOrder_SUCCESS': {
            return { ...state, Cart: null, cartQty: 0, OrderPlaceSuccess: true };
        }
        case 'addOrder_FAILURE': {
            console.log("============ order =========", action);
            return { ...state, error: true, message: action.payload };
        }
        case 'Add_To_Cart': {
            const data = addProductToCart(action.payload);
            return { ...state, Cart: data, cartQty: data.Items.length };
        }

        case 'Clear_Cart': {
            return { ...state, Cart: null, cartQty: 0 };
        }

        case 'show_cart_detail_modal': {
            return { ...state, modalVisible: !state.modalVisible };
        }
        case 'Remove_Data_From_Card': {
            return { ...state, Cart: null, cartQty: 0 };

        }
        case 'cart_UPDATE': {
            return { ...state, Cart: null, cartQty: 0, error: false, success: false, message: '', OrderPlaceSuccess: false, OrderLoader: false }
        }
        case 'cartStatus_UPDATE': {
            return { ...state,  error: false, success: false, message: '', OrderPlaceSuccess: false, OrderLoader: false }
        }

        default:
            return state;
    }


    function addProductToCart(data) {
        const oldState = state.Cart && state.Cart.Items;
        const product = data.product;
        const action = data.action;

        const restaurantName = data.restaurantName;
        const restaurantId = data.restaurantId;
        let cartObj = null;
        if (oldState && oldState.length > 0) {
            let ItemObj = {
                productId: product._id,
                title: product.title,
                unitPrice: product.price,
                totalPrice: product.price,
                qty: 1
            }

            const index = _.findIndex(oldState, x => x.productId === product._id);
            if (index === -1) {
                oldState.push(ItemObj);
            } else {
                if (action === "plus") {
                    oldState[index].qty = oldState[index].qty + 1
                } else {
                    oldState[index].qty = oldState[index].qty - 1
                }

                oldState[index].totalPrice = oldState[index].unitPrice * oldState[index].qty;

            }

            cartObj = {
                restaurantName: state.Cart.restaurantName,
                restaurantId: state.Cart.restaurantId,
                Items: oldState
            }

        } else {
            let ItemObj = {
                productId: product._id,
                title: product.title,
                unitPrice: product.price,
                totalPrice: product.price,
                qty: 1
            }
            cartObj = {
                restaurantName: restaurantName,
                restaurantId: restaurantId,
                Items: [ItemObj]
            }
        }
        return cartObj;
    }
}

import React from 'react'

const InfoSection = ({title, desc}) => {
    return (
        <div className="infoSection">
            <h2>{title}</h2>
            <p>{desc}</p>
        </div>
    )
}

export default InfoSection

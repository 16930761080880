import React, { useEffect } from 'react'
import { Row, Col, Card, Button, Empty } from "antd";
import { useDispatch, useSelector } from 'react-redux'
import { DataEntryModalAction } from 'redux/actions/Common'
import { DataGetAction } from 'redux/actions/http'
import ProductForm from './components/product/productForm'
import ProductItem from './components/product/productItem'


import Layout from 'components/LayoutForPortal'
const Products = () => {

    const dispatch = useDispatch();
    const prodProps = useSelector(state => state.product);
    const modalType = "show_product_modal";

    const showModal = (Item) => {
        dispatch(DataEntryModalAction(modalType, Item));
    }

    const fetchProduct = () => {
        dispatch(DataGetAction('getProduct', { query: "all" }));
    }
    useEffect(fetchProduct, [])

    const {Products} = prodProps;
    return (
        <Layout>
            <Card title={<h2>Product</h2>} extra={<Button type="primary" onClick={() => showModal(null)} className="gx-d-flex gx-flex-column gx-justify-content-center gx-mb-0">Add Product</Button>}>
                <Row className="gx-m-0">
                    {Products.length > 0 && Products.map((product, index) => (<Col key={index} xl={6} md={8} sm={12} xs={24}>
                        <ProductItem showModal={showModal} key={index} grid product={product} />
                    </Col>
                    ))}
                    {Products.length === 0 && <Col xl={24} md={24} sm={24} xs={24}><Empty description="No Record Found"/></Col>}
                </Row>
            </Card>
            <ProductForm
                type={modalType}
                title="product"
            />
        </Layout>
    )
}

export default Products

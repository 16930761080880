import React, { useState } from 'react'
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { ImageUpdateOnProfile } from 'redux/actions/Common'
import Widget from "components/Widget";


const FrontImage = () => {

    const dispatch = useDispatch();

    const frontImageUrl = useSelector(state => state.profile.FrontImage);
    const [loader, setLoader] = useState(false);
   
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoader(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl => {
                message.success('Front Image uploaded Successfully');
                dispatch(ImageUpdateOnProfile('frontImage', imageUrl));
                setLoader(false);
            }


            );
        }
    };

    const uploadButton = (
        <div>
            {loader ? <LoadingOutlined /> : <PlusOutlined />}
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    const token = localStorage.getItem('token');

    return (
        <Widget title="Front Image" extra="" styleName="gx-card-tabs gx-card-tabs-right gx-card-profile">

            <Upload
                listType="picture-card"
                headers={{ 'Authorization': token }}
                data={{ 'type': 'frontImage' }}
                className="avatar-uploader gx-mt-3"
                showUploadList={false}
                action={`${process.env.REACT_APP_ROOT_URL}/uploadImage`}
                beforeUpload={beforeUpload}
                onChange={handleChange}
            >
                {!loader ? frontImageUrl ? <img src={frontImageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton : <LoadingOutlined />}
            </Upload>
        </Widget>
    )
}

export default FrontImage

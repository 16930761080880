import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router'
import Auth from "./Auth";
import Common from './Common'
import Order from './orders'
import transaction from './transaction'
import profile from './profile'

import Category from './restaurant/portal/category'
import Product from './restaurant/portal/product'
import restaurant from './careCenter/restaurant'
import Cart from './Cart'
import Users from './users'
import Donation from './Donation'
import Filters from './Filters'
import Activity from './Activity'
import Dashboard from './dashboard'
import resCategory from './resCategory'
import lightBox from './LightBox'
import wallet from './wallet'

export default (history) => combineReducers({
  router: connectRouter(history),
  auth: Auth,
  commonR: Common,
  Filter: Filters,
  order: Order,
  profile: profile,
  transaction: transaction,
  restaurant: restaurant,
  category: Category,
  product: Product,
  cart: Cart,
  user: Users,
  donation: Donation,
  activity: Activity,
  dashboard: Dashboard,
  resCategory: resCategory,
  lightBox: lightBox,
  wallet: wallet


});

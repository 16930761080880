import React, { useState, useRef } from 'react';
import { Tag, Input, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const EditableTagGroup = ({tags, setTags, maxTags}) => {
    const initialState = {
        inputVisible: false,
        inputValue: '',
        editInputIndex: -1,
        editInputValue: '',
    };
    const [inputVisible, setInputVisible] = useState(initialState.inputVisible);
    const [inputValue, setInputValue] = useState(initialState.inputValue);
    const [editInputIndex, setEditInputIndex] = useState(initialState.editInputIndex);
    const [editInputValue, setEditInputValue] = useState(initialState.editInputValue);
    const inputEl = useRef(null);
    const inputEll = useRef(null);
    const handleClose = removedTag => {
        let NewTags = tags.filter(tag => tag !== removedTag);
        console.log(NewTags);
        setTags(NewTags);
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = e => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        let TagObj = [];
        if (inputValue && tags.indexOf(inputValue) === -1) {
            TagObj = [...tags, inputValue];
        }
        console.log("======= TagObj",TagObj);
        setTags(TagObj);
        setInputVisible(false);
        setInputValue('');
    };

    const handleEditInputChange = e => {
        setEditInputValue(e.target.value);
    };

    const handleEditInputConfirm = () => {
        const newTags = [...tags];
        newTags[editInputIndex] = editInputValue;

        setTags(newTags);
        setEditInputIndex(-1);
        setEditInputValue('');

    };

    const color = ["purple","geekblue","blue","red","yellow", "magenta"];
   
    return (
        <div>
            {tags.map((tag, index) => {
                if (editInputIndex === index) {
                    return (
                        <Input
                            ref={inputEl}
                            key={tag}
                            size="small"
                            className="tag-input gx-w-25"
                            value={editInputValue}
                            onChange={handleEditInputChange}
                            onBlur={handleEditInputConfirm}
                            onPressEnter={handleEditInputConfirm}
                        />
                    );
                }

                const isLongTag = tag.length > 20;

                const tagElem = (
                    <Tag
                        className="edit-tag"
                        key={tag}
                        color={color[index]}
                        closable={true}
                        onClose={() => handleClose(tag)}
                    >
                        <span
                            onDoubleClick={e => {
                                    setEditInputIndex(index);
                                    setEditInputValue(tag);
                                    e.preventDefault();
                                
                            }}
                        >
                            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                        </span>
                    </Tag>
                );
                return isLongTag ? (
                    <Tooltip title={tag} key={tag}>
                        {tagElem}
                    </Tooltip>
                ) : (
                        tagElem
                    );
            })}
            {inputVisible && (
                <Input
                    ref={inputEll}
                    type="text"
                    size="small"
                    className="tag-input gx-w-25"
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                />
            )}
            {(!inputVisible && tags.length <= maxTags) && (
                <Tag className="site-tag-plus" onClick={showInput}>
                    <PlusOutlined /> New Tag
                </Tag>
            )}
        </div>
    );
}


export default EditableTagGroup;
import React from 'react'
import { Row, Col } from 'antd'
import Organizer from 'components/Organizer'
import R0 from 'assets/homepage/R2.png'
import R1 from 'assets/homepage/R1.png'
import R2 from 'assets/homepage/R1.png'
import R3 from 'assets/homepage/R1.png'
import R4 from 'assets/homepage/R1.png'
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { TwitterSquareFilled, FacebookFilled } from '@ant-design/icons'

const Index = (props) => {
    const headerImages = props.ResProps && props.ResProps.headerImages
    const color = ["purple", "orange", "blue", "red", "yellow", "magenta"];


    const renderTags = () => {

        if (props.ResProps) {
            return props.ResProps.tags.map(
                (Item, index) => <span className="gx-mr-2" style={{
                    width: '63px',
                    height: '24px',
                    background: ` 0% 0 % no - repeat padding- box`,
                    backgroundColor: color[index],
                    borderRadius: '6px',
                    padding: '5px 15px',
                    color: '#fff'
                }}>{Item}</span>);
        }
    }

    console.log("========== props.ResProps.frontImageUrl =========", props.ResProps);

    const renderDepartmentInfo = () => {
        if (props.ResProps && props.ResProps.department) {
            const dataArray = props.ResProps.department.split(',');
            return dataArray.map(Item => {
                return <span>{Item}</span>
            })
        }
    }
    return (
        <>
            <Row className="CareCenterPromo">
                <Col xl={12} md={12} sm={24} xs={24} className="gx-pr-0 gx-pl-0">
                    <div>
                        <img src={props.ResProps !== null? props.ResProps.frontImageUrl ? props.ResProps.frontImageUrl  : R0:R0} alt="" height={400} width="100%" />
                    </div>
                </Col>
                <Col xl={6} md={6} sm={24} xs={24} className="gx-px-0">
                    <div>
                        <img src={headerImages ? headerImages[0] ? headerImages[0] : R4 : R4} alt="" height={200} width="100%" />
                        <img src={headerImages ? headerImages[1] ? headerImages[1] : R3 : R3} alt="" height={200} width="100%" />
                    </div>
                </Col>
                <Col xl={6} md={6} sm={24} xs={24} className="gx-pl-0 gx-pr-0">
                    <div className="careCenterShareImages">
                        <img src={headerImages ? headerImages[2] ? headerImages[2] : R1 : R1} alt="" height={200} width="100%" />
                        <div className="SharedButton careCenterTop">
                            <FacebookShareButton url="https://ub4i.digitalsensei.net/careCenter" >
                                <span className="shareButton facebook">
                                    <FacebookFilled className="socialFooter" />
                                            Share it!
                                        </span>
                            </FacebookShareButton>
                            <TwitterShareButton url="https://ub4i.digitalsensei.net/careCenter" >
                                <span className="shareButton twitter">
                                    <TwitterSquareFilled className="socialFooter" />
                                            Tweet it!
                                        </span>
                            </TwitterShareButton>
                        </div>
                        <img src={headerImages ? headerImages[3] ? headerImages[3] : R2 : R2} alt="" height={200} width="100%" />
                    </div>

                </Col>
            </Row>


            <div className="gx-container gx-mb-5 gx-pt-3">
                <Row className="gx-mr-3 ">
                    <Col xl={6} md={6} sm={24} xs={24}>
                        <div className="careCenterNameAndTags">
                            <h2 className="gx-font-weight-bold" style={{ textTransform: 'capitalize' }}>{props.ResProps && props.ResProps.name}</h2>
                            <p><i className="icon icon-geo-location gx-mr-2" style={{ color: '#22343D' }}></i> {props.ResProps && props.ResProps.location}</p>
                            {renderTags()}
                        </div>
                    </Col>

                    <Col xl={18} md={18} sm={24} xs={24}>
                        <p>{props.ResProps && props.ResProps.situation}</p>
                        <Row>
                            <Col xl={7} md={7} sm={24} xs={24}>
                                <div className="">
                                    <p className="h5 gx-mb-0">Employees</p>
                                    <span>{props.ResProps ? props.ResProps.noEmployee : 0}</span>
                                </div>
                            </Col>
                            <Col xl={7} md={7} sm={24} xs={24}>
                                <div className="gx-d-flex gx-flex-column">{renderDepartmentInfo()}</div>
                            </Col>
                            <Col xl={10} md={10} sm={24} xs={24}>
                                <div className="organizerContainer gx-d-flex gx-flex-row gx-justify-content-between gx-align-items-center">
                                    <Organizer name={props.ResProps && props.ResProps.name} title="Organizer" />
                                    <a href={`mailto: ${props.ResProps ? props.ResProps.email : ''}`} className="ant-btn  gx-pt-0">Contact</a>
                                </div>
                            </Col>

                        </Row>

                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Index

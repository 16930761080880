import React from "react";
import { Layout } from "antd";

import App from "routes/index";
import { useRouteMatch } from "react-router-dom";


const MainApp = () => {

  const match = useRouteMatch();



  return (
    <Layout className="gx-app-layout">
      <Layout>
          <App match={match} />
      </Layout>
    </Layout>
  )
};
export default MainApp;


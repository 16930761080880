import React from 'react'
import HorizontalNav from '../components/menuContent'
import Logo from '../components/logo'
import { useDispatch, useSelector } from 'react-redux';
import { SimpleModalAction, logoutFn } from 'redux/actions/Common';
import { useHistory } from 'react-router-dom'
import {
    DownOutlined
} from '@ant-design/icons';
import { Menu, Dropdown } from 'antd'
const Index = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const openSignInModal = () => {
        dispatch(SimpleModalAction('login', true))
    }
 
    const openSignUpModal = () => {
        dispatch(SimpleModalAction('signUp', true))
    }

    const authProps = useSelector(state => state.auth);


    const logout = () => {
        dispatch(logoutFn());
    }
    const redirectDashboard = () => {
        let url = "/admin/dashboard";
        if (authProps.LogeInUser.role === "restaurant") {
            url = "/restaurant/dashboard"
        } else if (authProps.LogeInUser.role === "careCenter") {
            url = "/careCenter/dashboard"
        }
        history.push(url);
    }


    const menu = (
        <Menu>
            <Menu.Item>
                <span className="gx-pointer" onClick={() => redirectDashboard()}>Dashboard</span>
            </Menu.Item>
            <Menu.Item>
                <span className="gx-pointer" onClick={() => logout()}>Logout</span>
            </Menu.Item>
        </Menu>
    );

    const renderSignIn = () => {
        if (authProps.LogeInUser && authProps.LogeInUser.name) {
            return (<Dropdown overlay={menu} trigger={['click']}>
                <span className="ant-dropdown-link gx-pointer " onClick={e => e.preventDefault()}>
                    {authProps.LogeInUser.name} <DownOutlined />
                </span>
            </Dropdown>)
        } else {
            return <>
                <span className="gx-pointer gx-link" onClick={() => openSignInModal()}> Sign In</span>
                <span className="btn btn-primary gx-pointer gx-link gx-pt-0 gx-ml-4 gx-mb-0" onClick={() => openSignUpModal()}> Get Started</span>
            </>


        }
    }

    return (
        <div className=" gx-header-horizontal gx-above-header-horizontal gx-w-100">
            <div className="gx-header-horizontal-top">
                <div className="gx-container">
                    <div className="gx-header-horizontal-top-flex gx-justify-content-between">
                        <div className="gx-header-horizontal-top-left gx-justify-content-sm-center">
                            <p className="gx-mb-0 gx-text-truncate"><a href="/home"><Logo /></a></p>
                        </div>
                        <div className="gx-header-horizontal-top-right">
                            <div className="gx-header-horizontal-top-flex">
                                <HorizontalNav />
                                <span className="gx-mr-3">|</span>
                                {renderSignIn()}

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index

import React, { useEffect } from 'react'
import Widget from 'components/Widget'
import RecentDonationList from 'components/BasicList'
import { useDispatch, useSelector } from 'react-redux'
import { SimpleModalAction } from 'redux/actions/Common'
import { DataGetAction } from 'redux/actions/http'
import { Button } from 'antd';

const Index = ({ showDonation, styledName, id, page }) => {


    const dispatch = useDispatch();

    const pubProp = useSelector(state => state.user.RecentDonations);
    const donationProps = useSelector(state => state.donation);

    const openDonationModal = () => {
        dispatch(SimpleModalAction('Donation', true));
    }

    const fetchDonation = () => {
        dispatch(DataGetAction('getSingleDonation', { query: id, page: page }));
    }

    useEffect(fetchDonation, [])

    const fetchDonationAfterSuccess = () => {
        if (donationProps.success) {
            dispatch(DataGetAction('getSingleDonation', { query: id, page: page }));
        }
    }

    useEffect(fetchDonationAfterSuccess, [donationProps.success])


    let donations = pubProp && pubProp.donations.slice(0, 8);
    if (donations && showDonation) {
        donations = donations.slice(0, 3);
    }

    console.log("================= pubProp ============",pubProp);

    return (
        <Widget styleName={`gx-ml-2 ${styledName}`}>
            {showDonation && <div className="donationAmount">
                <h2 className="gx-font-weight-bold">${pubProp ? pubProp.TotalAmount : 0}</h2>
                <p style={{ color: '#ADB1C9' }}>Raised to feed care workers in 24 days</p>
            </div>}
            {showDonation && <div className="DonationButton">
                <Button type="primary" className="gx-d-flex gx-w-100 gx-flex-row gx-justify-content-center" onClick={() => openDonationModal()} style={{ lineHeight: '0px' }}>Donate Now</Button>
            </div>}
            <div className="RecentDonation">
                <h3 className="gx-font-weight-bold">Recent donations</h3>
                <RecentDonationList data={pubProp && donations ? donations : []} maxItem={4} />
            </div>
        </Widget>
    )
}

export default Index

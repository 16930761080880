import React from 'react'
import HorizontalNav from '../components/menuContent'
import Logo from '../components/logo'
import { useDispatch } from 'react-redux';
import { SimpleModalAction } from 'redux/actions/Common';
const Index = () => {


    const dispatch = useDispatch()
    const openSignInModal = () => {
        dispatch(SimpleModalAction('login',true))
    }
  
    const openSignUpModal = () => {
        dispatch(SimpleModalAction('signUp',true))
    }

    return (
        <div className=" gx-header-horizontal gx-above-header-horizontal gx-w-100 inner gx-position-fixed" style={{zIndex:'9'}}>
            <div className="gx-header-horizontal-top">
                <div className="gx-container">
                    <div className="gx-header-horizontal-top-flex gx-justify-content-between">
                        <div className="gx-header-horizontal-top-left gx-justify-content-sm-center">
                            {/* <i className="icon icon-alert gx-mr-3" /> */}
                            <p className="gx-mb-0 gx-text-truncate"><a href="/home"><Logo /></a></p>
                        </div>
                        <div className="gx-header-horizontal-top-right">
                            <div className="gx-header-horizontal-top-flex">
                                <HorizontalNav />
                                <span className="gx-mr-3">|</span>
                                <span className="gx-pointer gx-link" onClick={() => openSignInModal()}> Sign In</span>
                                <span className="btn btn-primary gx-pointer gx-link gx-pt-0 gx-ml-4 gx-mb-0" onClick={() => openSignUpModal()}> Get Started</span>
                         
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index

import React from 'react'
import ReactImageVideoLightbox from 'react-image-video-lightbox';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux'
const Index = () => {

    const dispatch = useDispatch();
    const lightBoxProps = useSelector(state => state.lightBox);

    const closeLightBox = () => {
        dispatch({
            type: "hide_light_box_SUCCESS"
        })
    }

    return (
        <Modal
            visible={lightBoxProps.visible}
            className="lightBoxModal"
            centered
            onCancel={() => closeLightBox()}
            closable={false}
            footer={false}
        >
            <ReactImageVideoLightbox
                data={lightBoxProps.data}
                startIndex={0}
                showResourceCount={false}
                onCloseCallback={() => closeLightBox()}
            />
        </Modal>
    )
}

export default Index


const INIT_STATE = {
    DonationModalVisible: false,
    DonationLoader: false,
    success: false,
    Donations: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'Simple_Modal_State_Donation': {
            return { ...state, DonationModalVisible: !state.DonationModalVisible };
        }
       case 'getDonation_SUCCESS': {
            return { ...state, Donations: action.payload.Record };
        }
        case 'donation_SUCCESS': {
            return { ...state, success: true, DonationModalVisible: !state.DonationModalVisible, DonationLoader: !state.DonationLoader };
        }
        case 'Donation_Start': {
            return { ...state, DonationLoader: !state.DonationLoader };
        }
        case 'donation_UPDATE': {
            console.log("============ here ========")
            return { ...state, success: false, DonationLoader: false, DonationModalVisible: false };
        }
        case 'Donation_STOP': {
            return { ...state, DonationLoader: !state.DonationLoader };
        }
        default:
            return state;
    }
}

import React, {  useEffect } from 'react';
import { Modal, Form, Input, Select, Upload, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DataEntryModalAction } from 'redux/actions/Common'
import { DataGetAction, DataRequestAction, updateAllState } from 'redux/actions/http'
import { InboxOutlined } from '@ant-design/icons';
import alerts from 'alerts'
const { Option } = Select;

interface Values {
  title: string;
  description: string;
  modifier: string;
}

interface CollectionCreateFormProps {
  visible: boolean;
  onCreate: (values: Values) => void;
  onCancel: () => void;
}

const CollectionCreateForm: React.FC<CollectionCreateFormProps> = ({
  visible,
  initialValues,
  Categories,
  loader,
  title,
  onCreate,
  onCancel,
}) => {


  const token = localStorage.getItem('token');




  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      title={`${initialValues === null ? `Add` : 'Edit'} ${title}`}
      className="formModal"
      okText={`${initialValues === null ? 'Add' : 'Update'}`}
      cancelText="Cancel"
      confirmLoading={loader}
      onCancel={() => { onCancel() }}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            form.resetFields();
            onCreate(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={initialValues}
      >
        <Form.Item label="Dragger">
          <Form.Item
            name="dragger"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            noStyle
          >
            <Upload.Dragger 
            multiple={false}
            name="file" 
            action={`${process.env.REACT_APP_ROOT_URL}/uploadProductImage`}
            headers={{ 'Authorization': token }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">Support for a single or bulk upload.</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        <Form.Item
          name="title"
          className="gx-mt-3"
          label="Name"
          defaultValue={initialValues && initialValues.title}
          rules={[{ required: true, message: 'Please input the title of collection!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          defaultValue={initialValues && initialValues.description}

        >
          <Input.TextArea type="textarea" rows={5} />
        </Form.Item>
        <Form.Item
          name="price"
          label="Price"
          defaultValue={initialValues && initialValues.price}
          rules={[{ required: true, message: 'Please input the price!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="category"
          label="Category"
          defaultValue={[initialValues && initialValues.category]}
          rules={[{ required: true, message: 'Please select category!' }]}
        >
          <Select style={{ width: '100%' }}>
            {Categories.map(Item => {
              return <Option value={Item._id}>{Item.title}</Option>
            })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const ProductForm = ({ type, title }) => {

  const dispatch = useDispatch();
  const productProps = useSelector(state => state.product)
  const catProps = useSelector(state => state.category)
  const onCreate = values => {

    values.imageUrl = values.dragger[0].response.file.location;
    values.dragger = 0;
    if (productProps.selectedItem === null) {
      dispatch(DataRequestAction('POST', 'addProduct', values, '', 'product', false));

    } else {
      values._id = productProps.selectedItem._id;
      dispatch(DataRequestAction('POST', 'editProduct', values, '', 'product', false));
    }


  };

  const fetchCategory = () => {
    dispatch(DataGetAction('getCategory', { query: "all" }))
  }

  useEffect(fetchCategory, [])

  if (productProps.success) {
    message.success(alerts.record_updated_success);
    dispatch(updateAllState('product'));
  }

  return <CollectionCreateForm
    visible={productProps.modalVisible}
    initialValues={productProps.selectedItem}
    loader={productProps.productLoader}
    title={title}
    Categories={catProps.Categories}
    onCreate={onCreate}
    onCancel={() => dispatch(DataEntryModalAction(type, null))}
  />

};

export default ProductForm;
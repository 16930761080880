import React from "react";
import Widget from 'components/Widget'
import Tags from 'components/Tags/Display'
import { useHistory } from 'react-router-dom'
import { Tooltip, message } from 'antd'
import {
  QuestionCircleOutlined
} from '@ant-design/icons';
const RestaurantItem = ({ product }) => {
  const { frontImageUrl = "https://via.placeholder.com/600x400", name, tags, location, balanceInfo } = product;

  const { balance } = balanceInfo && balanceInfo[0] ? balanceInfo[0] : { balance: 0 };

  const color = ["purple", "geekblue", "blue", "red", "yellow", "magenta"];
  const history = useHistory();
  const renderTags = () => {
    const data = tags;
    if (data) {
      return data.map((Item, index) => <Tags color={color[index]} title={Item} />);
    }
  }

  const selectedRestaurant = (data) => {
    if (product.restaurantStatus) {
      history.push('/careCenter/products/', { data, name });
    }else{
      message.info("Restaurant is closed")
    }
  }
  return (
    <Widget styleName="productItemWidget">
      <div className={`gx-product-item  gx-product-horizontal`} onClick={() => { selectedRestaurant(product._id) }}>
        <div className="gx-product-image">
          <div className="gx-grid-thumb-equal">
            <span className="gx-link gx-grid-thumb-cover">
              <img alt="Remy Sharp" src={frontImageUrl} />
            </span>
          </div>
          {product.restaurantStatus ? <span className="restaurantStatus open"> Available</span> :
            <span className="restaurantStatus close">Closed</span>}
        </div>

        <div className="gx-product-body gx-pointer" >
          <h3 className="gx-product-title gx-mb-0">{name} <Tooltip placement="topLeft" title={"Available balance you can use for order from this restaurant "}><span className="gx-float-right ">${balance} <span className="gx-link" style={{
            fontSize: '16px'
          }}><QuestionCircleOutlined /></span></span></Tooltip></h3>
          <p className="gx-mt-2 gx-mb-3" style={{ color: '#848484', fontSize: '13px' }}><i className="icon icon-geo-location gx-mr-1"></i>{location}</p>
          {renderTags()}
        </div>
      </div>
    </Widget>
  )
};

export default RestaurantItem;


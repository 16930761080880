const INIT_STATE = {
    LoginVisible: false,
    modalVisible: false,
    selectedItem: null,
    orderLoader: false,
    error: false,
    success: false,
    Transactions: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'getTransaction_SUCCESS': {
            return { ...state, Transactions: action.payload.Record };
        }

        default:
            return state;
    }
}
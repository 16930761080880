import React from 'react';
import { Modal, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DataEntryModalAction } from 'redux/actions/Common'
import { updateAllState, DataRequestAction } from 'redux/actions/http'
import { CloseCircleOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom'
import moment from 'moment';
const { Option } = Select;


const OrderDetail = ({ type, title }) => {

    const dispatch = useDispatch();
    const location = useLocation();
    const orderProps = useSelector(state => state.order)
    const { selectedItem } = orderProps;

    console.log("============== selectedItem",selectedItem);
    if (orderProps.success) {
        dispatch(updateAllState('order'));
    }

    const handleChange = (value) => {
        let data = {
            _id: selectedItem._id,
            hpName:selectedItem.hpInfo[0]['name'],
            hpEmail:selectedItem.hpInfo[0]['email'],
            resName:selectedItem.rpInfo[0]['name'],
            resEmail:selectedItem.rpInfo[0]['email'],
            status: value
        }
        dispatch(DataRequestAction('POST', "editOrder", data, '', 'order', false));
    }

    const renderPrice = () => {
        if (selectedItem) {
            let price = 0;
            selectedItem.Items.map(Item => {
                price = price + Item.totalPrice
                return Item
            })
            return price
        }
    }
    const renderItems = () => {
        if (selectedItem) {
            return selectedItem.Items.map(Item => {
                return <h3>{Item.title} x {Item.qty}</h3>
            })
        }
    }

    const renderStatusOption = (status="") => {

       // const optionArray = { "careCenter": ["pending", "received"], "superAdmin": ["approve", "reject", "completed"], "restaurant": ["preparing", "dispatched", "delivered"] };

        let selectedOption = [];

        if (location.pathname === "/admin/orders") {
            if (status === "pending") {
                selectedOption = ["approve", "reject"]
            }
            if (status === "received") {
                selectedOption = ["completed"]
            }

        }


        if (location.pathname === "/restaurant/orders") {
            if (status === "approve") {
                selectedOption = ["preparing", "dispatched"]
            }
            if (status === "preparing") {
                selectedOption = ["dispatched"]
            }
        }


        if (location.pathname === "/careCenter/orders") {

            if (status === "dispatched") {
                selectedOption = ["received"];
            }
        }


        return selectedOption.map(Item => {
            return <Option value={Item}>{Item.charAt(0).toUpperCase() + Item.slice(1)}</Option>

        })
    }

    return <Modal
        title={`Order No ${selectedItem && selectedItem.orderNumber}`}
        visible={orderProps.modalVisible}
        closeIcon={<CloseCircleOutlined />}
        centered
        onCancel={() => dispatch(DataEntryModalAction(type, null))}
        footer={false}
    >
        <div className="orderDetail">
            <p><span>Date:</span> <span className="gx-font-weight-bold">{selectedItem && moment(selectedItem.updatedAt).format('DD,MMM YYYY')}</span> <span className="gx-float-right">Status:
             <Select size="small" className="gx-ml-2" defaultValue={selectedItem && selectedItem.status} style={{ width: 120 }} onChange={handleChange}>
                    {selectedItem && renderStatusOption(selectedItem.status)}
                </Select></span></p>
            <p><span>Customer name:</span> <span className="gx-font-weight-bold">{selectedItem && selectedItem.hpInfo[0]['name']}</span></p>
            <p><span>Restaurant name:</span> <span className="gx-font-weight-bold">{selectedItem && selectedItem.restaurantName}</span></p>
            <p><span>Customer address:</span> <span className="gx-font-weight-bold">{selectedItem && selectedItem.location}</span></p>
            <p><span>Contact:</span> <span className="gx-font-weight-bold">{selectedItem && selectedItem.phoneNumber}</span></p>
            <p><span>Amount:</span> <span className="gx-font-weight-bold">${renderPrice()}</span></p>
            <div className="orderList">
                <p>Customer's order</p>
                {renderItems()}
            </div>
        </div>

    </Modal>
};

export default OrderDetail;
import React from 'react';
import { Modal, Button, Form, Input, Spin, Empty, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DataEntryModalAction } from 'redux/actions/Common'
import { DataRequestAction, updateAllState } from 'redux/actions/http'
import { CloseCircleOutlined } from '@ant-design/icons';
import { AddToCart, ClearCart } from 'redux/actions/Common'



const OrderDetail = ({ type, title }) => {

    const dispatch = useDispatch();
    const cartProps = useSelector(state => state.cart)
    const authProps = useSelector(state => state.auth)

    const { Cart, OrderLoader } = cartProps;
    const { LogeInUser } = authProps;

    const addProductToCart = (product, action) => {
        product._id = product.productId;
        product.price = product.unitPrice;
        dispatch(AddToCart(product, Cart.restaurantName, Cart.restaurantId, action))
    }


    let initialValues = {
        restaurantName: Cart ? Cart.restaurantName : "",
        location: LogeInUser ? LogeInUser.location : "",
        phoneNumber: LogeInUser ? LogeInUser.phoneNumber : ""
    };

    const renderOrderDetail = () => {
        const Items = Cart && Cart.Items;
        if (Items) {
            return Items.map(Item => {
                return (
                    <div className="gx-d-flex gx-flex-row gx-align-Item-center">
                        <div className="gx-d-flex gx-flex-row gx-align-Items-center">
                            <Button size="small" className="gx-mr-2" onClick={() => addProductToCart(Item, "mins")}>-</Button>
                            <span className="gx-mr-1 gx-pt-1">{Item.qty}</span>
                            <Button size="small" onClick={() => addProductToCart(Item, "plus")}>+</Button>
                        </div>
                        <h3 className="gx-ml-2">{Item.title}</h3>
                    </div>
                )
            })

        }

    }

    const onFinish = values => {
        values.Items = Cart.Items;
        values.createdRp = Cart.restaurantId;
        values.createdHp = LogeInUser._id;
        dispatch(DataRequestAction('POST', 'addOrder', values, '', 'order', false));
    };


    if (cartProps.error) {
        message.error(cartProps.message)
        dispatch(updateAllState('cartStatus'));
    }


    const renderCartDetail = () => {
        if (Cart && Cart.Items.length > 0) {
            return <div className="orderDetail">

                <div className="orderList gx-mb-3">
                    <p>Your order</p>
                    {renderOrderDetail()}
                </div>
                <div className="gx-mt-2">
                    <Form
                        layout="vertical"
                        name="basic"
                        onFinish={onFinish}
                        initialValues={initialValues}
                    >
                        <Form.Item
                            label="Restaurant name"
                            name="restaurantName"
                        >
                            <Input readOnly />
                        </Form.Item>
                        <Form.Item
                            label="Delivery address"
                            name="location"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Phone number"
                            name="phoneNumber"
                        >
                            <Input />
                        </Form.Item>


                        <Form.Item className="gx-mt-5 gx-mb-0 gx-d-flex gx-flex-row">

                            {!OrderLoader && <Button type="primary" className="gx-pt-0 gx-float-right gx-mb-0" htmlType="submit">
                                Place Order
                                             </Button>
                            }
                            {OrderLoader && <span className="gx-d-flex gx-float-right">
                                <Spin className="gx-pt-0 gx-float-right">
                                    <Button type="primary" className="gx-pt-0 gx-float-right gx-mb-0" htmlType="button">
                                        Place Order
                                    </Button>
                                </Spin></span>
                            }
                            <Button className="gx-pt-0 gx-float-right gx-mb-0 gx-mr-3" htmlType="button" onClick={() => clearCart()}>
                                Cancel
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        } else {
            return <Empty description="Your cart is empty" />
        }
    }

    const clearCart = () => {
        dispatch(ClearCart())
    }

    return <Modal
        title="Your order"
        visible={cartProps.modalVisible}
        closeIcon={<CloseCircleOutlined />}
        centered
        onCancel={() => { dispatch(DataEntryModalAction(type, null)) }}
        footer={false}
    >
        {renderCartDetail()}

    </Modal>
};

export default OrderDetail;
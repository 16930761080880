import React from "react";
import Widget from 'components/Widget'
import { Popover } from 'antd'
import DeleteConfirm from 'components/Modal/DeleteConfirm'

const ProductItem = ({ product, grid, showModal }) => {
  const { imageUrl= "https://via.placeholder.com/600x400", title, price, description } = product;



  return (
    <Widget styleName="productItemWidget">
      <div className={`gx-product-item  ${grid ? 'gx-product-vertical' : 'gx-product-horizontal'}`}>
        <div className="gx-product-image">
          <div className="gx-grid-thumb-equal">
            <span className="gx-link gx-grid-thumb-cover">
              <img alt="Remy Sharp" src={imageUrl} />
            </span>
          </div>
        </div>

        <div className="gx-product-body">
          <h3 className="gx-product-title gx-mb-0">{title} </h3>
          <p style={{ color: '#848484', fontSize: '13px' }}>{description}</p>
          <h4 className="gx-link">${price}  <span className="gx-float-right">
            <Popover content={
              <div>
                <span className="gx-w-100 gx-d-block gx-mb-2" onClick={() => showModal(product)}><i className="icon icon-edit gx-mr-2"></i> Edit</span>
                <span className="gx-w-100 gx-d-block"><i className="icon icon-close-circle gx-mr-2" ></i><DeleteConfirm type="deleteProduct" selectedItem={product} buttonName="Delete" title="Are you sure delete product?" content="" /></span>
              </div>
            } title="" trigger="click"><i className="icon icon-ellipse-h"></i></Popover>
          </span></h4>
        </div>
      </div>
    </Widget>
  )
};

export default ProductItem;


import React, { useEffect } from 'react'
import { Row, Col, Empty } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DataGetAction } from 'redux/actions/http'
import Nav from 'components/Navigation'
import Footer from 'components/Footer'
import SearchFilter from 'components/Filters/SearchFilter'
import ProductItem from 'components/ProductItem'

const CareCenterList = () => {

    const dispatch = useDispatch();
    const ResProps = useSelector(state => state.user);

    const fetchPublicProfile = () => {
        dispatch(DataGetAction('getProductList', { query: "Hp" }));
    }

    useEffect(fetchPublicProfile, []);




    const renderProductList = () => {
        if (ResProps.ProductList && ResProps.ProductList.length > 0) {
            const data = ResProps.ProductList;
            return data.map(Item => {
                return (
                    <Col xl={6} md={6} sm={24} xs={24} className="hospital">
                        <ProductItem product={Item} url={`/careCenters/${Item.name}`} />
                    </Col>
                )
            })
        } else {
            return <Empty description="No Data Found" />
        }
    }



    return (
        <Row style={{ background: '#fff' }}>
            <Col xl={24} md={24} sm={24} xs={24}>
                <Nav />
            </Col>
            <Col xl={24} md={24} sm={24} xs={24}>
                <div className="gx-container gx-mt-4" style={{paddingTop:'80px'}}>
                    <Row>
                        <Col xl={16} md={16} sm={24} xs={24}>
                            <h2 className="gx-font-weight-bold">All care centers</h2>
                            <p>Share your support in this period and donate food to front line workers.</p>
                        </Col>
                        <Col xl={8} md={8} sm={24} xs={24}>
                            <SearchFilter
                                placeholder={`Search by Name`}
                                searchByValueChange={true}
                                searchByClick={true}
                                maxValueLengthForStartSearch={3}
                                type="getProductList"
                                query={{ query: "Hp", searchBy: "name", search: "" }}
                            />
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col xl={24} md={24} sm={24} xs={24}>
                <div className="gx-container gx-mt-4">
                    <Row>
                        {renderProductList()}
                    </Row>
                </div>
            </Col>
            <Col xl={24} md={24} sm={24} xs={24} className="footerContainer">
                <Footer />
            </Col>
        </Row>
    )
}

export default CareCenterList

import React from 'react'
import { Row, Col } from 'antd';
import { Menu } from "antd";
import { Link } from "react-router-dom";
import Modal from '../Modal/SimpleModal';
import SignIn from '../Auth/Signin'
import Signup from '../Auth/Signup'
import ForgotPassword from '../Auth/ForgotPassword'
import DonationModal from 'containers/Donation/DonationModal'
import { useDispatch } from 'react-redux';
import { updateAllState } from 'redux/actions/http';
import { useSelector } from 'react-redux'
import { TwitterSquareFilled, FacebookFilled } from '@ant-design/icons'

const Index = () => {


    const dispatch = useDispatch();
    const LoginVisible = useSelector(state => state.commonR.LoginVisible)
    const SignUpVisible = useSelector(state => state.commonR.SignUpVisible)
    const ForgotPasswordVisible = useSelector(state => state.commonR.ForgotPasswordVisible)

    const onAuthModalClose = () => {
        dispatch(updateAllState('auth'));
    }

    return (
        <Row>
            <Col xl={24} md={24} sm={24} xs={24}>
                <Menu
                    mode="horizontal"
                    className="gx-text-center gx-mt-4 gx-mb-3"
                    style={{
                        borderBottom: '0px',

                    }}
                >
                    <Menu.Item key="about">
                        <Link to="/about-us">About</Link>
                    </Menu.Item>
                    <Menu.Item key="faq">
                        <Link to="/faq">FAQ</Link>
                    </Menu.Item>
                    <Menu.Item key="term">
                        <Link to="/term">Terms</Link>
                    </Menu.Item>
                    <Menu.Item key="private">
                        <Link to="/privatePolicy">Private policy</Link>
                    </Menu.Item>
                </Menu>
            </Col>
            <Col xl={24} md={24} sm={24} xs={24}>
                <div className="gx-text-center gx-mb-3"><span style={{
                    fontSize: '23px',
                    marginRight: '6px'
                }}><a href="/"><FacebookFilled className="socialFooter" /></a></span>
                    <span style={{
                        fontSize: '23px',
                        marginLeft: '6px'
                    }}><a href="/"><TwitterSquareFilled className="socialFooter" /></a></span></div>
            </Col>
            <Col xl={24} md={24} sm={24} xs={24}>
                <p className="gx-text-center gx-mb-3" style={{ color: "#22343D" }}>Copyright© UB4I 2020. All rights reserved</p>
            </Col>

            <Modal visible={LoginVisible || false} modalFor="login" onClose={() => onAuthModalClose()}> <SignIn /> </Modal>
            <Modal visible={SignUpVisible || false} modalFor="signUp" onClose={() => onAuthModalClose()}> <Signup /> </Modal>
            <Modal visible={ForgotPasswordVisible || false} modalFor="forgotPassword" onClose={() => onAuthModalClose()}> <ForgotPassword /> </Modal>
            <DonationModal />
        </Row>
    )
}

export default Index

import React, { useEffect } from "react";
import { Avatar, Timeline } from "antd";
import { useSelector, useDispatch } from 'react-redux'
import { DataGetAction } from 'redux/actions/http'
import moment from 'moment';

const TimeLineItem = Timeline.Item;

function getName(task, shape) {
  if (task.avatar === '') {
    let nameSplit = task.name.split(" ");
    if (task.name.split(" ").length === 1) {
      const initials = nameSplit;
      return <Avatar shape={shape} className="gx-ml-3 gx-size-40 gx-bg-primary">{initials}</Avatar>
    } else {
      const initials = nameSplit;
      return <Avatar shape={shape} className="gx-ml-3 gx-size-40 gx-bg-cyan">{initials}</Avatar>
    }
  } else {
    return <Avatar shape={shape} className="gx-size-40" src={task.avatar} />;
  }
}

const RecentActivity = (props) => {


  const dispatch = useDispatch();
  const activityProps = useSelector(state => state.activity)

  const fetchRecentActivity = () => {
    dispatch(DataGetAction('recentActivity', 'all'))
  }
  useEffect(fetchRecentActivity, [])



  const renderActivity = () => {
    if (activityProps.Activities) {
      return activityProps.Activities.map((Item, index) => {
        return <TimeLineItem className="gx-mb-3" key={"timeline" + index} mode="alternate"
          dot={getName({ "avatar": "", name: "UB4i" }, props.shape)}>
          <p className="gx-mb-0 gx-ml-3">{Item.description}</p>
          <p className="gx-mb-0 gx-ml-3">{moment(Item.createdAt).format('Do, MMMM YYYY hh:mm a')}</p>
        </TimeLineItem>
      })
    }
  }
  return (
    <div className="gx-entry-sec">

      <div className="gx-timeline-info">
        <h4 className="gx-timeline-info-day gx-mb-4">Recent Activities</h4>
        <Timeline>
          {renderActivity()}
        </Timeline>
      </div>
    </div>
  );
};

export default RecentActivity;

import _ from 'lodash';


const INIT_STATE = {
    LoginVisible: false,
    modalVisible: false,
    selectedItem: null,
    categoryLoader: false,
    error: false,
    success: false,
    Categories: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'getCategory_SUCCESS': {
            return { ...state, Categories: action.payload.Record };
        }

        case 'show_category_modal': {
            return { ...state, modalVisible: !state.modalVisible, selectedItem: action.payload };
        }

        case 'category_START': {
            return { ...state, categoryLoader: true };
        }
        case 'category_STOP': {
            return { ...state, categoryLoader: false };
        }



        case 'addCategory_SUCCESS': {
            return { ...state, success: true, Categories: [action.payload.Record, ...state.Categories], categoryLoader: false };
        }
        case 'addCategory_FAILURE': {
            return { ...state, error: true, success: false, categoryLoader: false, message: action.payload };
        }

        case 'editCategory_SUCCESS': {
            let oldCat = state.Categories;
            let newCat = oldCat.map(Item => {
                if (Item._id === action.payload.Record._id) {
                    return action.payload.Record;
                } else {
                    return Item
                }
            })
            return { ...state, success: true, Categories: newCat, categoryLoader: false };
        }
        case 'editCategory_FAILURE': {
            return { ...state, error: true, success: false, categoryLoader: false, message: action.payload };
        }
        case 'category_UPDATE': {
            return { ...state, modalVisible: false, categoryLoader: false, error: false, success: false, message: "" };
        }

        case 'deleteCategory_SUCCESS': {
            const deletedRecord = action.payload;
            const oldCat = state.Categories;
            const newCat = _.filter(oldCat, x => x._id !== deletedRecord);
            return { ...state, Categories: newCat };
        }

        default:
            return state;
    }
}

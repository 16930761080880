import React, { useEffect } from 'react'
import { Card } from "antd";
import { useDispatch, useSelector } from 'react-redux'
import Layout from 'components/LayoutForPortal'
import Orders from 'components/Table/BasicTable'
import OrderDetail from 'components/Order/orderDetail'
import { DataGetAction } from 'redux/actions/http'
import { DataEntryModalAction } from 'redux/actions/Common'
import { useLocation } from 'react-router-dom'
import Search from 'components/Filters/SearchFilter'
import moment from 'moment';
const OrderList = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const OrderProps = useSelector(state => state.order);
  const logInUser = useSelector(state => state.auth.LogeInUser);
  const modalType = "show_order_detail_modal";

  const role = logInUser.role;
  let SearchPlaceHolder = "careCenter/Restaurant"

  let columns = [

    {
      title: 'Date',
      dataIndex: 'updatedAt',
      render: (record) => moment(record).format('DD,MMM YYYY')
    },

    {
      title: 'Delivery address',
      dataIndex: 'location',
    },
    {
      title: 'Contact',
      dataIndex: 'phoneNumber',
    },
    {
      title: 'Amount',
      dataIndex: 'totalAmount',
      render: (record) =>  <span>${record}</span>
    },
    {
      title: 'Order status',
      dataIndex: 'status',
      render: (record) => {
        const statusColor = { "pending": "#FAB005", "preparing": "#2C4BFF", "dispatched": "#F03E3D", "delivered": "#ADB1C9", "received": "#2C4BFF", "Completed": "#18C123" }
        return <span className="status" style={{
          textTransform: 'capitalize'
        }}><span className="dot" style={{ background: statusColor[record] }}></span>{record}</span>
      }
    },

    {
      title: 'Actions',
      render: (record) => <div><span className="gx-link" onClick={() => showOrderDetail(record)}>View Order</span></div>,
      width: 150
    },
  ];

  if (role === "superAdmin") {
    columns = [{
      title: 'Date',
      dataIndex: 'updatedAt',
      render: (record) => moment(record).format('DD,MMM YYYY')
    }, {
      title: 'Care Center',
      dataIndex: 'hpInfo',
      render: (record) => record[0]['name']
    }, {
      title: 'Restaurant name',
      dataIndex: 'restaurantName',
    }, {
      title: 'Amount',
      dataIndex: 'totalAmount',
      render: (record) => <span>${record}</span>

    }, {
      title: 'Available Balance',
      dataIndex: 'availableBalance',
      render: (record) => <span>${record}</span>

      // render: (record, data) => {
      //   let price = 0;
      //   let message = <span className="outReach">(out of reach)</span>
      //   data.Items.map(Item => {
      //     price = price + Item.totalPrice;
      //     return Item
      //   })
      //   if (parseInt(price) < parseInt(record && record[0] ? record[0].balance : 0)) {
      //     message = <span className="inReach">(in reach)</span>
      //   }
      //   return record && <span>${record && record[0] ? record[0].balance : 0}{message}</span>
      // }
    }, {
      title: 'Order status',
      dataIndex: 'status',
      render: (record) => {
        const statusColor = { "pending": "#FAB005", "preparing": "#2C4BFF", "dispatched": "#F03E3D", "delivered": "#ADB1C9", "received": "#2C4BFF", "Completed": "#18C123" }
        return <span className="status" style={{
          textTransform: 'capitalize'
        }}><span className="dot" style={{ background: statusColor[record] }}></span>{record}</span>
      }
    }, {
      title: 'Actions',
      render: (record) => <div><span className="gx-link" onClick={() => showOrderDetail(record)}>View Order</span></div>,
      width: 150
    }];
  }



  if (role === "restaurant") {
    SearchPlaceHolder = "Care Center"
    columns = [{
      title: 'Order',
      dataIndex: 'orderNumber',
    }, {
      title: 'Customer name',
      dataIndex: 'hpInfo',
      render: (record) => record[0]['name']
    }, ...columns];
  }


  else if (role === "careCenter") {
    SearchPlaceHolder = "Restaurant"

    columns = [{
      title: 'Order',
      dataIndex: 'orderNumber',
    }, {
      title: 'Restaurant name',
      dataIndex: 'restaurantName',
    }, ...columns];

  }

  let queryString = "all";
  let status = "all"
  if (location.pathname === "/careCenter/orders") {
    queryString = "Hp"
    status = "all"
  }

  if (location.pathname === "/restaurant/orders") {
    queryString = "Rp"
    status = "pending"
  }



  const showOrderDetail = (Item) => {
    dispatch(DataEntryModalAction(modalType, Item));
  }

  const fetchOrder = () => {

    dispatch(DataGetAction('getOrder', { query: queryString, status: status }));
  }
  useEffect(fetchOrder, [location.pathname])

  return (
    <Layout>
      <Card title={<h2>Orders</h2>} extra={<Search
        placeholder={`Search by ${SearchPlaceHolder}`}
        searchByValueChange={true}
        searchByClick={true}
        maxValueLengthForStartSearch={3}
        type="getOrder"
        query={{ query: queryString, searchBy: "name", search: "" }}
      />}>
        <Orders columns={columns} data={OrderProps.Orders} />
      </Card>
      <OrderDetail
        type={modalType}
        title="order"
      />
    </Layout>
  )
}

export default OrderList


import _ from 'lodash';
import jwtDecoder from 'jwt-decode'
const INIT_STATE = {
    Activities: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {


        case 'recentActivity_SUCCESS':{
            return { ...state, Activities: action.payload.Record };
        }

        case 'newActivity_SUCCESS': {
            const data = action.payload.Record;
            const rectActivity = getActivity(data, state.Activities);
            return { ...state, Activities: rectActivity };
        }
        default:
            return state;
    }
}


function getActivity(data, oldActivity) {
    const decoded = jwtDecoder(localStorage.getItem('token'));
    let newOrderObj = oldActivity;

    if (!_.isArray(data) && data.createdFor === decoded._id) {
        newOrderObj = [data, ...oldActivity]
    } else {
        const activity = _.filter(data, x => x.createdFor === decoded._id);
        newOrderObj = [...activity, ...oldActivity]
    }
    return newOrderObj;


}
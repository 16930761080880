import React, { useEffect } from 'react'
import Layout from 'components/LayoutForPortal'
import { Card, Row, Col, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { DataGetAction, updateAllState } from 'redux/actions/http'
import ProductItem from './components/productItem'
import CartDetail from './CartDetail'
import CartDetailModal from 'components/Order/cartDetail';
import { Socket } from 'socket'
import { DataEntryModalAction } from 'redux/actions/Common';

const Product = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const restaurantProps = useSelector(state => state.restaurant);
    const authPros = useSelector(state => state.auth)
    const cartProps = useSelector(state => state.cart)
    const walletProps = useSelector(state => state.wallet);


    const fetchRestaurant = () => {
        if (location.state) {
            dispatch(DataGetAction('getAllRestaurantProduct', { "query": location.state.data }));
            dispatch(DataGetAction('recentBalance', { "query": location.state.data }));
        }
    }


    useEffect(fetchRestaurant, [])

    if (cartProps.OrderPlaceSuccess) {
        message.success("Your Order Place Successfully");
        dispatch(DataEntryModalAction('show_cart_detail_modal', null))
        let socketConnect = Socket.getInstance(authPros.LogeInUser._id);
        socketConnect.fetchOrder();

        dispatch(updateAllState("cart"))
    }

    const { products } = restaurantProps;
    const restaurantStatus = restaurantProps[`restaurantStatus_${location.state.data}`];
    const renderRestaurant = () => {
        if (products) {
            return products.map((Item, index) => {
                return (
                    <Col key={index} xl={6} md={8} sm={12} xs={24}>
                        <ProductItem
                            availableBalance={walletProps.availableBalance}
                            key={index}
                            product={Item}
                            restaurantName={location.state.name}
                            restaurantId={location.state.data}
                            restaurantStatus={restaurantStatus}
                            
                        />
                    </Col>
                )
            })
        }
    }

    return (
        <Layout>
            <Card title={<h2>{location && location.state.name} <span style={{
                color: '#666',
                fontSize: '17px'
            }}>(Available Balance: ${walletProps.availableBalance}, Restaurant:{restaurantStatus ? <span className="openRestaurant">Open</span> : <span className="closeRestaurant">Closed</span>}) </span></h2>} extra={<CartDetail />}>
                <Row className="gx-m-0">
                    {renderRestaurant()}
                </Row>
            </Card>
            <CartDetailModal
                type="show_cart_detail_modal"
                title="order"
            />
        </Layout>
    )
}

export default Product

import React, { useEffect } from 'react'
import { Card } from "antd";
import { useDispatch, useSelector } from 'react-redux'
import Layout from 'components/LayoutForPortal'
import DonationList from 'components/Table/BasicTable'
import { DataGetAction } from 'redux/actions/http'
import moment from 'moment';
const Donations = () => {

  const columns = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      render: (record) => moment(record).format('DD,MMM YYYY'),
      width: 200
    },
    {
      title: 'Resource',
      dataIndex: 'hpInfo',
      render: (record) => <span>{record[0].name}</span>
    },
    {
      title: 'Amount',
      dataIndex: 'careCenterAmount',
    },
    {
      title: 'Tip UB4i',
      dataIndex: 'tip',
    },
    {
      title: 'Name',
      dataIndex: 'donnerInfo',
      render: (record) => <span>{record.fullName}</span>
    },
    {
      title: 'Email',
      dataIndex: 'donnerInfo',
      render: (record) => <span>{record.email}</span>

    }

  ];

  const dispatch = useDispatch();
  const donationProps = useSelector(state => state.donation);


  const fetchCategory = () => {
    dispatch(DataGetAction('getDonation', { query: "all" }));
  }
  useEffect(fetchCategory, [])

  return (
    <Layout>
      <Card title={<h2>Categories</h2>} >
        <DonationList columns={columns} data={donationProps && donationProps.Donations} />
      </Card>
    </Layout>
  )
}

export default Donations

const INIT_STATE = {
    modalVisible: false,
    error: false,
    success: false,
    ProfileInfo: null,
    headerImages: [],
    FrontImage: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'getProfileInfo_SUCCESS': {

            return {
                ...state,
                ProfileInfo: action.payload.Record,
                headerImages: action.payload.Record[0].headerImages,
                FrontImage: action.payload.Record[0].frontImageUrl,
            };
        }
        case 'updateProfile_SUCCESS': {
            return { ...state, ProfileInfo: [action.payload.Record], success: true };
        }
        case 'Image_update_headerImages': {
            return { ...state, headerImages: [...action.payload] };
        }
        case 'Image_update_frontImage': {
            return { ...state, FrontImage: action.payload };
        }

        case 'show_profile_modal': {
            return { ...state, modalVisible: !state.modalVisible };
        }

        case 'profile_UPDATE': {
            console.log("=========== update all ========");
            return { ...state, success: false, modalVisible: false, error: false, message: "" };
        }
        default:
            return state;
    }
}
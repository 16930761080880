import React from 'react';

export const growthData = [
  { name: 'Page A', price: 0 },
  { name: 'Page B', price: 0 },
  { name: 'Page C', price: 0 },
  { name: 'Page D', price: 0 },
  { name: 'Page D', price: 0 }

];
export const growth2Data = [
  { name: 'Page A', revenue: 0 },
  { name: 'Page B', revenue: 0 },
  { name: 'Page C', revenue: 0 },
  { name: 'Page D', revenue: 0 },
  { name: 'Page D', revenue: 0 }

];

export const trafficData = [
  { name: 'Page A', income: 0 },
  { name: 'Page B', income: 0 },
  { name: 'Page C', income: 0 },
  { name: 'Page D', income: 0 },
  { name: 'Page D', income: 0 }
];

export const trafficRaiseData = [
  { name: 'Page A', traffic: 0 },
  { name: 'Page B', traffic: 0 },
  { name: 'Page C', traffic: 0 },
  { name: 'Page D', traffic: 0 },
  { name: 'Page D', traffic: 0 }
];


export const revenueData = [
  { name: 'Page A', revenue: 0 },
  { name: 'Page B', revenue: 0 },
  { name: 'Page C', revenue: 0 },
  { name: 'Page D', revenue: 0 },
];

export const propertiesData = [
  { name: 'Page A', properties: 0 },
  { name: 'Page B', properties: 0 },
  { name: 'Page C', properties: 0 },
  { name: 'Page D', properties: 0 },
  { name: 'Page D', properties: 0 },
  { name: 'Page H', properties: 0 },
  { name: 'Page K', properties: 0 },
];
export const citiesData = [
  { name: 'Page A', cities: 0 },
  { name: 'Page B', cities: 0 },
  { name: 'Page C', cities: 0 },
  { name: 'Page D', cities: 0 },
  { name: 'Page D', cities: 0 },
  { name: 'Page H', cities: 0 },
  { name: 'Page K', cities: 0 },
];
export const visitsData = [
  { name: 'Page A', visit: 0 },
  { name: 'Page B', visit: 0 },
  { name: 'Page C', visit: 0 },
  { name: 'Page D', visit: 0 },
  { name: 'Page D', visit: 0 },
  { name: 'Page H', visit: 0 },
  { name: 'Page K', visit: 0 },
];

export const queriesData = [
  { name: 'Page A', queries: 0 },
  { name: 'Page B', queries: 0 },
  { name: 'Page C', queries: 0 },
  { name: 'Page D', queries: 0 },
  { name: 'Page D', queries: 0 },
  { name: 'Page H', queries: 0 },
  { name: 'Page K', queries: 0 },
];

export const increamentData = [
  { name: 'Page A', price: 0 },
  { name: 'Page B', price: 0 },
  { name: 'Page C', price: 0 },
  { name: 'Page D', price: 0 },
  { name: 'Page D', price: 0 },
  { name: 'Page H', price: 0 },
  { name: 'Page K', price: 0 },
];

export const increamentRevenueData = [
  { name: 'Page A', revenue: 0 },
  { name: 'Page B', revenue: 0 },
  { name: 'Page C', revenue: 0 },
  { name: 'Page D', revenue: 0 },
  { name: 'Page D', revenue: 0 },
  { name: 'Page H', revenue: 0 },
  { name: 'Page K', revenue: 0 },
];

export const lineData = [
  { name: 'Page A', price: 0 },
  { name: 'Page B', price: 0 },
  { name: 'Page C', price: 0 },
  { name: 'Page D', price: 1700 },
  { name: 'Page D', price: 0 },
  { name: 'Page D', price: 10 },
  { name: 'Page D', price: 0 },
];

export const lineData2 = [
  { name: 'Page A', traffic: 0 },
  { name: 'Page B', traffic: 0 },
  { name: 'Page C', traffic: 0 },
  { name: 'Page D', traffic: 1700 },
  { name: 'Page D', traffic: 0 },
  { name: 'Page D', traffic: 10 },
  { name: 'Page D', traffic: 0 },
]


export const recentActivity = [
  {
    id: 1,
    day: 'Today',
    tasks: [
      {
        id: 1,
        name: 'Mila Alba',
        title: [<span className="gx-link" key={1}>Mila Alba</span>, ' left a 5 star review on ',
        <span className="gx-link" key={2}>Albama’s House</span>],
        avatar: "https://via.placeholder.com/150x150",
        imageList: [],
      },
      {
        id: 2,
        name: 'Bob Builder',
        title: ['Callback request from ', <span className="gx-link" key={3}>Bob Builder</span>, ' for the property ',
          <span className="gx-link" key={4}>Dimitri House</span>],
        avatar: "https://via.placeholder.com/150x150",
        imageList: [],
      },
      {
        id: 3,
        name: 'Tom Moody',
        title: ['Congratulations to ', <span className="gx-link" key={5}>Tom Moody</span>,
          ' for joining 0+ club '],
        avatar: "https://via.placeholder.com/150x150",
        imageList: [],
      },
      {
        id: 4,
        name: 'Norman Dolphi',
        title: ['Norman Dolphi is looking for a house in New Jersy, USA'],
        avatar: '',
        imageList: [],
      },
    ]
  },
  {
    id: 2,
    day: 'Yesterday',
    tasks: [
      {
        id: 5,
        name: 'Kily Johns',
        title: ['Agent ',
          <span className="gx-link" key={7}>Kily Johns</span>, ' has added 7 new photos to the property ',
          <span key={6} className="gx-link">Albama’s House</span>],
        avatar: '',
        imageList: ["https://via.placeholder.com/150x150", "https://via.placeholder.com/150x150", "https://via.placeholder.com/150x150", "https://via.placeholder.com/150x150", "https://via.placeholder.com/150x150", "https://via.placeholder.com/150x150", "https://via.placeholder.com/150x150",],
      },
      {
        id: 6,
        name: 'Tom Moody',
        title: ['Welcome to a new agent ', <span className="gx-link" key={8}>Tom Moody in the Company</span>],
        avatar: "https://via.placeholder.com/150x150",
        imageList: [],
      },
      {
        id: 7,
        name: 'Oliver Shorter',
        title: [<span className="gx-link" key={9}>Oliver Shorter</span>, ' is looking for an office space in ',
        <span className="gx-link" key={0}>Colorado, USA</span>],
        avatar: "https://via.placeholder.com/150x150",
        imageList: [],
      }, {
        id: 8,
        name: 'Mila Alba',
        title: [<span className="gx-link" key={11}>Mila Alba</span>, ' left a 5 star review on ',
        <span className="gx-link" key={12}>Albama’s House</span>],
        avatar: "https://via.placeholder.com/150x150",
        imageList: [],
      },
    ]
  }];
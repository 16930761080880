import React, { useEffect } from 'react'
import { Card, Button, message } from "antd";
import { useDispatch, useSelector } from 'react-redux'
import Layout from 'components/LayoutForPortal'
import UserList from 'components/Table/BasicTable'
import DeleteConfirm from 'components/Modal/DeleteConfirm'
import { DataGetAction, DataRequestAction, updateAllState } from 'redux/actions/http'
import { useLocation, useHistory } from 'react-router-dom'
import Search from 'components/Filters/SearchFilter'
import alert from 'alerts'


const User = () => {

    const history = useHistory()
    const redirectToRestaurantPage = (record) => {
        const data = record._id;
        const name = record.name;
        history.push(`/${record.role}s/${name}`,{data, name})
    }

    const columns = [
        {
            title: 'Restaurant name',
            dataIndex: 'name',
            width: 200
        },
        {
            title: 'Email',
            dataIndex: 'email',
            width: 200
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            width: 200
        },
        {
            title: 'Location',
            dataIndex: 'location',
            width: 200
        },
        {
            title: 'Actions',
            render: (record) => {
                if (record.status === "pending") {
                    return (
                        <div>
                            <Button onClick={() => { redirectToRestaurantPage(record) }} type="primary" size="small" className="gx-mr-2 gx-pt-0" htmlType="button">View</Button>
                            <Button onClick={() => { updateUserStatus(record, 'active') }} type="primary" size="small" className="gx-mr-2 gx-pt-0 approve" htmlType="button">Approve</Button>
                            <Button onClick={() => { updateUserStatus(record, 'reject') }} size="small" className="gx-pt-0 reject" type="primary" htmlType="button">Reject</Button>
                        </div>)
                } else if (record.status === "reject") {
                    return <div>
                        <span className="gx-link gx-pointer" onClick={() => { updateUserStatus(record, 'pending') }}>Un-Block</span> | <DeleteConfirm type="deleteCategory" selectedItem={record} buttonName="Delete" title="Are you sure delete this task?" content="" />
                    </div>
                } else {
                    return (
                        <div>
                            <span className="gx-link gx-pointer" onClick={() => { updateUserStatus(record, 'reject') }}>Block</span> | <DeleteConfirm type="deleteCategory" selectedItem={record} buttonName="Delete" title="Are you sure delete this task?" content="" />
                        </div>)
                }
            },
            width: 200
        },
    ];

    const dispatch = useDispatch();
    const userProps = useSelector(state => state.user);
    const location = useLocation();
    let queryString = location.pathname === "/admin/careCenter" ? "Rh" : "Rp";

    const fetchCategory = () => {
        dispatch(DataGetAction('getAllUsers', { query: queryString }));
    }
    useEffect(fetchCategory, [location.pathname])


    const updateUserStatus = (record, status) => {
        dispatch(DataRequestAction('POST', 'editUser', { _id: record._id, status: status }, '', 'user', false));
    }

    if (userProps.success) {
        message.success(alert.record_updated_success);
        dispatch(updateAllState('User'));
    }
    if (userProps.error) {
        message.error(alert.record_updated_error);
        dispatch(updateAllState('User'));
    }

    return (
        <Layout>
            <Card title={<h2>Restaurants</h2>} extra={
                <Search
                    placeholder="Search restaurant by name"
                    searchByValueChange={true}
                    searchByClick={true}
                    maxValueLengthForStartSearch={3}
                    type="getAllUsers"
                    query={{ query: queryString, searchBy: "name", search: "" }}
                />}>
                <UserList columns={columns} data={userProps.Users} />
            </Card>
        </Layout>
    )
}

export default User

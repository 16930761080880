import _ from 'lodash';


const INIT_STATE = {
    LoginVisible: false,
    modalVisible: false,
    selectedItem: null,
    productLoader: false,
    error: false,
    success: false,
    Products: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'getProduct_SUCCESS': {
            return { ...state, Products: action.payload.Record };
        }

        case 'show_product_modal': {
            return { ...state, modalVisible: !state.modalVisible, selectedItem: action.payload };
        }

        case 'product_START': {
            return { ...state, productLoader: true };
        }
        case 'product_STOP': {
            return { ...state, productLoader: false };
        }



        case 'addProduct_SUCCESS': {
            return { ...state, success: true, Products: [action.payload.Record, ...state.Products], productLoader: false };
        }
        case 'addProduct_FAILURE': {
            return { ...state, error: true, success: false, productLoader: false, message: action.payload };
        }

        case 'editProduct_SUCCESS': {
            let oldCat = state.Products;
            let newCat = oldCat.map(Item => {
                if (Item._id === action.payload.Record._id) {
                    return action.payload.Record;
                } else {
                    return Item
                }
            })
            return { ...state, success: true, Products: newCat, productLoader: false };
        }
        case 'editProduct_FAILURE': {
            return { ...state, error: true, success: false, productLoader: false, message: action.payload };
        }
        case 'product_UPDATE': {
            return { ...state, modalVisible: false, productLoader: false, error: false, success: false, message: "" };
        }

        case 'deleteProduct_SUCCESS': {
            const deletedRecord = action.payload;
            const oldCat = state.Products;
            const newCat = _.filter(oldCat, x => x._id !== deletedRecord);
            return { ...state, Products: newCat };
        }

        default:
            return state;
    }
}

import store from '../store';

export const SocketResponseState = (type, payload) => {
    console.log("================= == ChatBetweenUser === Socket ",type, payload, "============ store ========", store);
    store.dispatch({
        type: `${type}_SUCCESS`,
        payload: payload
    });

}

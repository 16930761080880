const INIT_STATE = {
    data: [],
    visible: false,

};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'show_light_box_SUCCESS': {
            return { ...state, visible: true, data: action.payload }
        }
        case 'hide_light_box_SUCCESS': {
            return { ...state, visible: false }
        }

        default:
            return state;
    }
}

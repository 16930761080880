import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DataGetAction } from 'redux/actions/http'
import { Layout, Menu, Badge } from 'antd';
import { Link, useLocation } from "react-router-dom";
import MenuList from './Menu'
const { Sider } = Layout;

const Index = () => {

    const SideBarCollapsed = useSelector(state => state.commonR.SideBarCollapsed);
    const logInUser = useSelector(state => state.auth.LogeInUser);
    const pendingRequest = useSelector(state => state.user.pendingRequest);

    const role = logInUser ? logInUser.role : "careCenter";
    const location = useLocation();
    const MenuData = MenuList[role];
    const dispatch = useDispatch();

    const GetPendingRequest = () => {
        dispatch(DataGetAction('getPendingRequest', ''));
    }
    useEffect(GetPendingRequest, []);


    const renderMenu = () => {
        return MenuData.map(Item => {


            return <Menu.Item key={Item.url} >
                <Link to={Item.url}>
                    <i className={`icon icon-${Item.icon}`} />
                    <span>{Item.title}</span>
                    {Item.badge && pendingRequest && pendingRequest.length > 0 && <Badge className="gx-ml-3" count={pendingRequest[0][Item.badge]} />}
                </Link>
            </Menu.Item>
        })
    }
    return (
        <Sider trigger={null} collapsible collapsed={SideBarCollapsed} className="site-layout-background">
            <Menu mode="inline" defaultSelectedKeys={[location.pathname]} style={{ marginTop: '30px' }}>
                {renderMenu()}
            </Menu>
        </Sider>
    )
}

export default Index

import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";

import MainApp from "./MainApp";
import HomePage from "routes/Home";
import Restaurant from 'routes/Restaurant/restaurantList'
import SingleRestaurant from 'routes/Restaurant/index'
import CareCenter from 'routes/CareCenter'
import SingleCareCenter from 'routes/CareCenter/careCenterList'
import AboutUs from 'routes/About'
import SetNewPassword from 'routes/setNewPassword'
import { Socket } from 'socket'

import { setInitUrl } from "redux/actions/Auth";


const RestrictedRoute = ({ component: Component, location, token, ...rest }) =>
    <Route
        {...rest}
        render={props =>
            localStorage.getItem('token')
                ? <Component {...props} />
                : <Redirect
                    to={{
                        pathname: '/home',
                        state: { from: location }
                    }}
                />}
    />;


const App = () => {

    const dispatch = useDispatch();
    const { token, initURL, LogeInUser } = useSelector(({ auth }) => auth);
   
    const location = useLocation();
    const history = useHistory();
    const match = useRouteMatch();


    useEffect(() => {
        let link = document.createElement('link');
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = "/css/style.css";
        document.body.appendChild(link);
    });

    useEffect(() => {
        if (initURL === '') {
            dispatch(setInitUrl(location.pathname));
        }

    }, [dispatch, initURL, location.pathname, location.search]);


    useEffect(() => {
        if (location.pathname === '/') {
            if (token === null) {
                history.push('/home');
            } else if (initURL === '' || initURL === '/' || initURL === '/home') {
                history.push('/home');
            } else {
                history.push(initURL);
            }
        }
    }, [token, initURL, location, history]);
    if (localStorage.getItem('token')) {
        Socket.getInstance(LogeInUser._id);
    }
    return (

        <Switch>
            <Route exact path='/home' component={HomePage} />
            <Route exact path='/restaurant' component={Restaurant} />
            <Route exact path='/restaurants/:name' component={SingleRestaurant} />
            <Route exact path='/careCenter' component={SingleCareCenter} />
            <Route exact path='/careCenters/:name' component={CareCenter} />
            <Route exact path='/about-us' component={AboutUs} />
            <Route exact path='/setNewPassword/:guid' component={SetNewPassword} />
            <RestrictedRoute path={`${match.url}`} token={token} location={location}
                component={MainApp} />
        </Switch>
    )
};

export default memo(App);

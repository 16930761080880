import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SimpleModal from 'components/Modal/SimpleModal'
import { DataGetAction, DataGetActionAPI } from 'redux/actions/http'
import { useLocation } from 'react-router-dom'
import Donation from './Donation';


const DonationModal = () => {
    const state = useSelector(state => state.donation)

    const dispatch = useDispatch();
    const location = useLocation();

    const userProps = useSelector(state => state.user.AllHp);;
    const hospital = useSelector(state => state.user.AllHpp);;

    const fetchPublicProfile = () => {
        if (state.DonationModalVisible) {
            const query = { query: "Rp" }
            if (location.pathname.includes("restaurants")) {
                query.query = "Rh";
            }
            dispatch(DataGetAction('getAllHospital', query));

            if (location.pathname.includes('home')) {
                dispatch(DataGetActionAPI('getAllHospital', 'getAllHpp', { query: 'Rh' }));
            }

        }
    }

    useEffect(fetchPublicProfile, [state.DonationModalVisible]);

    console.log("============== hospital ===========", hospital);

    return (
        <SimpleModal
            visible={state.DonationModalVisible}
            modalFor="Donation"
            title="Donat Now"
            className="DonationModal"
        >
            <Donation
                AllHp={userProps}
                hospital={hospital}
                homePage={location.pathname.includes('home')?'homePage':'otherPage'}
                title={location.pathname.includes("restaurants") ? `${location.state && location.state.name} Restaurant` : `${location.state && location.state.name} Care Center`}
                titleField={location.pathname.includes("restaurants") ? "Choose care center to feed" : "Choose restaurant"}
                desc={location.pathname.includes("restaurants") ? "We will buy food from restaurant you will select" : "We will provide food to care center you will select"}
            />
        </SimpleModal>
    )
}

export default DonationModal

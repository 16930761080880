
import React from 'react'
import { Modal } from 'antd';
import {  useDispatch } from 'react-redux'
import { SimpleModalAction } from 'redux/actions/Common'
import { CloseCircleOutlined } from '@ant-design/icons';
const SimpleModal = (props) => {

    const dispatch = useDispatch();
   
    const closeModal = () => {
        dispatch(SimpleModalAction(props.modalFor, false))
        props.onClose && props.onClose()
    }

    return (
        <Modal
            title={props.title}
            visible={props.visible}
            className={props.className}
            closeIcon={<CloseCircleOutlined />}
            centered
            onCancel={() => closeModal()}
            footer={false}
        >
            {props.children}
        </Modal>

    )
}

export default SimpleModal

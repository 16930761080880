import React from 'react'
import Comments from 'components/SingleItem'
import { useSelector } from 'react-redux'
import moment from 'moment';

const CommentSection = ({ title, data }) => {

    const pubProp = useSelector(state => state.user.RecentDonations);
    console.log("=====donations====", pubProp && pubProp.donations);

    if (pubProp && pubProp.donations) {

        return (
            <div className="CommentSection">
                <h2>{title}</h2>
                {pubProp.donations.map(Item => {
                    return <Comments name={Item.donner.displayName} date={moment(Item.donner.commentDate).format('Do MMMM, YYYY h:mm a')}  desc={Item.donner.comment} />

                })}
            </div>
        )
    } else {
        return (<span></span>)
    }
}

export default CommentSection

import React, { useState } from 'react'
import { Upload, Modal, message, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { ImageUpdateOnProfile } from 'redux/actions/Common'
import { DataRequestAction } from 'redux/actions/http'
import Widget from "components/Widget";
import _ from 'lodash';

const { Search } = Input;
const FrontImage = () => {


    const headerImages = useSelector(state => state.profile.headerImages);
    const dispatch = useDispatch();

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');

    //const [fileList, setFileList] = useState([]);


    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const handleCancel = () => setPreviewVisible(false);

    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))

    };



    const saveHeaderVideoUrl = (url) => {
        dispatch(DataRequestAction('POST', 'updateProfile', {'headerVideoUrl': url }, '', 'profile', false));
    }


    const handleChange = (info) => {
        if (info.file.status === 'done') {
            message.success('Header Image uploaded Successfully');

        }
        dispatch(ImageUpdateOnProfile('headerImages', info.fileList));

        //  setFileList(info.fileList);
    }

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }


    const uploadButton = (
        <div>
            <PlusOutlined />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    const token = localStorage.getItem('token');
    const fileList = []
    _.map(headerImages, (x, index) => {
        fileList.push({ file: { url: x }, uid: index })
    })
    return (
        <Widget title="Header Images & Video" extra="" styleName="gx-card-tabs gx-card-tabs-right gx-card-profile">

            <div className="clearfix gx-mt-3">
                <Upload
                    action={`${process.env.REACT_APP_ROOT_URL}/uploadImage`}
                    headers={{ 'Authorization': token }}
                    data={{ 'type': 'headerImage' }}
                    accept=".png, .jpg, .jpeg"
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    beforeUpload={beforeUpload}
                >
                    {headerImages.length >= 4 ? null : uploadButton}
                </Upload>
                <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={handleCancel}
                >
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>

            </div>
            <Search
                placeholder="Enter the youTube url for header video"
                enterButton="Save"
                className="videoUrlButton"
                size="large"
                onSearch={value => saveHeaderVideoUrl(value)}
            />
        </Widget>
    )
}

export default FrontImage

import React from "react";
import Widget from 'components/Widget'
import Tags from 'components/Tags/Display'
import { useHistory } from 'react-router-dom'

const RestaurantItem = ({ product, url }) => {
  const { frontImageUrl = "https://via.placeholder.com/600x400", name, tags, location } = product;

  const color = ["purple", "geekblue", "blue", "red", "yellow", "magenta"];
  const history = useHistory();
  const renderTags = () => {
    const data = tags;
    if (data) {
      return data.map((Item, index) => <Tags color={color[index]} title={Item} />);
    }
  }

  const selectedRestaurant = (data) => {
    history.push(`${url}`, { data, name });
  }
  return (
    <Widget styleName="productItemWidget public">
      <div className={`gx-product-item  gx-product-horizontal`}  onClick={() => { selectedRestaurant(product._id) }}>
        <div className="gx-product-image">
          <div className="gx-grid-thumb-equal">
            <span className="gx-link gx-grid-thumb-cover">
              <img alt="Remy Sharp" src={frontImageUrl} />
            </span>
          </div>
        </div>

        <div className="gx-product-body gx-pointer">
          <h3 className="gx-product-title gx-mb-0">{name} </h3>
          <p className="gx-mt-2 gx-mb-3" style={{ color: '#848484', fontSize: '13px' }}><i className="icon icon-geo-location gx-mr-1"></i>{location}</p>
          {renderTags()}
        </div>
      </div>
    </Widget>
  )
};

export default RestaurantItem;

import React, { useEffect, useState } from 'react'
import { Row, Col, Empty } from 'antd';
import Tags from 'components/Tags/Display'
import { useDispatch, useSelector } from 'react-redux';
import { DataGetAction } from 'redux/actions/http'
import Nav from 'components/Navigation'
import Footer from 'components/Footer'
import SearchFilter from 'components/Filters/SearchFilter'
import ProductItem from 'components/ProductItem'
const RestaurantList = () => {

    const dispatch = useDispatch();
    const ResProps = useSelector(state => state.user);
    const categoryProps = useSelector(state => state.commonR.RestaurantCategory);
    const [selectedTags, setSelectedTags] = useState(["All"]);
    const fetchPublicProfile = () => {
        dispatch(DataGetAction('getProductList', { query: "Rp", searchBy:"foodType", search:selectedTags }));
        dispatch(DataGetAction('getCategories', ""));
    }

    useEffect(fetchPublicProfile, [selectedTags]);
    
  


    const renderProductList = () => {
        if (ResProps.ProductList && ResProps.ProductList.length > 0) {
            const data = ResProps.ProductList;
            return data.map(Item => {
                return (
                    <Col xl={12} md={12} sm={24} xs={24}>
                        <ProductItem product={Item} url={`/restaurants/${Item.name}`} />
                    </Col>
                )
            })
        } else {
            return <Empty description="No Data Found" />
        }
    }

    const addSelected = (Item) => {
        setSelectedTags([...selectedTags, Item]);
    }
    const removeSelected = (Item) => {
        const newArray = selectedTags.filter(x => x !== Item);
        setSelectedTags(newArray)
    }

    const renderCategories = () => {
        const data = categoryProps;
        if (data) {
            return data.map((Item, index) => {
                if (selectedTags.includes(Item)) {
                    return <span className="gx-pointer selectedTag" onClick={() => removeSelected(Item)} ><Tags color="#2C4BFF26" title={Item} /></span>
                } else {
                    return <span className="gx-pointer" onClick={() => addSelected(Item)} ><Tags title={Item} /></span>
                }
            })
        }
    }

    return (
        <Row style={{ background: '#fff' }}>
            <Col xl={24} md={24} sm={24} xs={24}>
                <Nav />
            </Col>
            <Col xl={24} md={24} sm={24} xs={24}>
                <div className="gx-container gx-mt-4" style={{paddingTop:'80px'}}>
                    <Row>
                        <Col xl={16} md={16} sm={24} xs={24}>
                            <h2 className="gx-font-weight-bold">All restaurant</h2>
                            <p>Share your support in this period and donate food to front line workers.</p>
                        </Col>
                        <Col xl={8} md={8} sm={24} xs={24}>
                            <SearchFilter
                                placeholder={`Search by Name`}
                                searchByValueChange={true}
                                searchByClick={true}
                                maxValueLengthForStartSearch={3}
                                type="getProductList"
                                query={{ query: "Rp", searchBy: "name", search: "" }}
                            />
                        </Col>
                        <Col xl={24} md={24} sm={24} xs={24} className="categoriesRestaurant">
                            {renderCategories()}
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col xl={24} md={24} sm={24} xs={24}>
                <div className="gx-container gx-mt-4 restaurantList">
                    <Row>
                        {renderProductList()}
                    </Row>
                </div>
            </Col>
            <Col xl={24} md={24} sm={24} xs={24} className="footerContainer">
                <Footer />
            </Col>
        </Row>
    )
}

export default RestaurantList

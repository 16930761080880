import React, { useState } from "react";
import Widget from "components/Widget";
import { useDispatch } from 'react-redux';
import { DataRequestAction } from 'redux/actions/http'
import { Button, Input } from "antd";

const { TextArea } = Input;

const About = ({ keyObj, title, desc, id }) => {

  const dispatch = useDispatch();
  const [stateAction, setActionButton] = useState("cancel");
  const [textValue, setTextValue] = useState(desc)

  const changeButton = (type) => {
    setActionButton(type);
  }

  const updateProfile = () => {
    dispatch(DataRequestAction('POST', 'updateProfile', { _id: id, [`${keyObj}`]: textValue }, '', 'profile', false));
    setActionButton("edit")
  }

  let actionButton = null;
  if (stateAction === "action") {
    actionButton = <><Button className="gx-pt-0" onClick={() => { changeButton("cancel") }} type="info">Cancel</Button><Button onClick={() => { updateProfile() }} className=" gx-pt-0" type="primary">Save</Button></>;
  } else {
    actionButton = <Button className="gx-pt-0" type="primary" onClick={() => { changeButton("action") }}>Edit</Button>;

  }



  return (
    <Widget title={title} extra={actionButton} styleName="gx-card-tabs gx-card-tabs-right gx-card-profile">
      {stateAction !== "action" && <p className="gx-mt-3">{desc}</p>}
      {stateAction === "action" && <TextArea className="gx-mt-3" rows={4} defaultValue={desc} onChange={(e) => setTextValue(e.target.value)} />}
    </Widget>
  );
}


export default About;

import React from 'react'
import { Elements } from '@stripe/react-stripe-js';
import DonationForm from './DonationForm'
import { loadStripe } from '@stripe/stripe-js';

const Donation = (props) => {

    const key = process.env.REACT_APP_STRIP_KEY;
    const stripePromise = loadStripe(key);

    console.log("================= stripePromise", key);
    return (
        <Elements stripe={stripePromise}>
            <DonationForm
                AllHp={props.AllHp}
                title={props.title}
                titleField={props.titleField}
                desc={props.desc}
                homePage={props.homePage}
                hospital={props.hospital}

            />
        </Elements>
    )
}

export default Donation

import React from 'react'
import Layout from 'components/LayoutForPortal'

const Settings = () => {
    return (
        <Layout>
            Settings
        </Layout>
    )
}

export default Settings

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DataGetAction } from 'redux/actions/http'
import SliderItem from 'components/Slider'
import UAParser from "ua-parser-js";

const Slider = ({ title,  ResProps, fetch,  deviceType  }) => {

    const dispatch = useDispatch();
    const otherProps = useSelector(state => state.user.others);

    const fetchOtherRH = () => {
        dispatch(DataGetAction('fetchOtherRH', { query: fetch, data: ResProps }));
    }

    useEffect(fetchOtherRH, []);

    return (
        <div className="slider">
            <h2>{title}</h2>

            <SliderItem type={fetch} data={otherProps} deviceType={deviceType} />


        </div>
    )
}

Slider.getInitialProps = ({ req }) => {
    let userAgent;
    if (req) {
      userAgent = req.headers["user-agent"];
    } else {
      userAgent = navigator.userAgent;
    }
    const parser = new UAParser();
    parser.setUA(userAgent);
    const result = parser.getResult();
    const deviceType = (result.device && result.device.type) || "desktop";
    return { deviceType };
  };
  

export default Slider

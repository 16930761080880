import React, { useEffect } from 'react';
import { Form, Input, Button, Spin, message } from 'antd';
import { SimpleModalAction } from 'redux/actions/Common';
import { DataRequestAction, updateAllState } from 'redux/actions/http';
import { useDispatch, useSelector } from 'react-redux'

const ForgotPassword = (props) => {

    const authProps = useSelector(state => state.auth);
    const dispatch = useDispatch();
   
    const OpenSignModal = () => {
        dispatch(updateAllState('auth'))
        dispatch(SimpleModalAction('forgotPassword', false))
        dispatch(SimpleModalAction('login', true))
    }

    const LoadPage = () => {
        return () => {
            dispatch(updateAllState('auth'));
        }
    }
    useEffect(LoadPage, [])

    const onFinish = values => {
        dispatch(DataRequestAction('POST', 'forgetPassword', values, '', 'forgot', false));
    };

    if(authProps.success){
        message.success('Email Send successfully');
        dispatch(updateAllState('auth'));
    }

    return (
        <div className="SignIn gx-p-5">

            <div className="welcomeMessage gx-text-center gx-mb-5">
                <h2 className="gx-font-weight-bold gx-mb-3">Forgot Your Password ?</h2>
                <p className="gx-mb-0">Don't worry. Recovering the password is easy.Just tell us the email you have registered with UB4i.</p>
                <p className={`gx-text-center ${authProps.error ? "error" : ""}`}> {authProps.message}</p>
            </div>

            <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Please input your Email!' }]}
                >
                    <Input placeholder="Email address" />
                </Form.Item>
             
                <Form.Item>
                    {!authProps.ShowForgotLoader && <Button type="primary" htmlType="submit" className="login-form-button gx-d-flex gx-w-100 gx-flex-row gx-justify-content-center" style={{ lineHeight: '0px' }}>
                        Send
                 </Button>}
                    {authProps.ShowForgotLoader && <Spin><Button type="primary" htmlType="button" className="login-form-button gx-d-flex gx-w-100 gx-flex-row gx-justify-content-center" style={{ lineHeight: '0px' }}>
                        Send
                 </Button></Spin>}
                </Form.Item>
                <p className="gx-text-center" onClick={() => OpenSignModal()}>Click here for <span className="gx-pointer gx-link">Login</span></p>
            </Form>
        </div>
    );
};

export default ForgotPassword;
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk';
import createRootReducer from '../reducers'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'


const createBrowserHistory = require('history').createBrowserHistory;


export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);

const middlewares = [thunk, routeMiddleware];


const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
  stateReconciler: hardSet,
}

const persistedReducer = persistReducer(persistConfig, createRootReducer(history))


const store = createStore(
  persistedReducer, // root reducer with router state
  compose(
    applyMiddleware(
      routerMiddleware(history), // for dispatching history actions
      ...middlewares
    ),
  ),
);

export const persisStore = persistStore(store)
export default  store



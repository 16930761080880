import React, { useEffect } from 'react'
import Layout from 'components/LayoutForPortal'
import { Row, Col } from 'antd'
import TotalRevenueCard from "components/Dashboard/TotalRevenueCard";
import TotalOrderRevenueCard from "components/Dashboard/TotalOrderRevenueCard";
import RecentActivity from "components/Dashboard/RecentActivity";
import { recentActivity } from "./data";
import Widget from "components/Widget/index";
import EcommerceStatus from "components/Dashboard/EcommerceStatus";
//import IconWithTextCard from "components/Dashboard/IconWithTextCard";
import { useDispatch, useSelector } from 'react-redux'
import { DataGetAction } from 'redux/actions/http'


const Dashboard = () => {

    const DashboardData = useSelector(state => state.dashboard);
    const dispatch = useDispatch();

    const fetchDashboardData = () => {
        dispatch(DataGetAction('getRestaurantDashboardData', ''))
    }

    useEffect(fetchDashboardData, [])

    return (
        <Layout>
            <Row>
                <Col xl={12} lg={12} md={8} sm={24} xs={24}>
                    <Row>
                        <Col xl={8} lg={8} md={8} sm={12} xs={24}>
                            <EcommerceStatus color="orange" icon="revenue-new" title={`$${DashboardData.totalOrderAmount}`} colorTitle="geekblue"
                                subTitle={<span>Total <br /> Orders Amount</span>} colorSubTitle="geekblue" />
                        </Col>
                        <Col xl={8} lg={8} md={8} sm={12} xs={24}>
                            <EcommerceStatus color="geekblue" icon="revenue-new" title={`$${DashboardData.completedOrderAmount}`} colorTitle="primary"
                                subTitle={<span>Complete <br /> Orders Amount</span>} colorSubTitle="grey" />
                        </Col>
                        <Col xl={8} lg={8} md={8} sm={12} xs={24}>
                            <EcommerceStatus icon="revenue-new" title={`$${DashboardData.inProgressOrderAmount}`} colorTitle="primary" subTitle={<span>InProcess <br /> Orders Amount</span>}
                                colorSubTitle="grey" />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={14} lg={14} md={14} sm={12} xs={24}>
                            <TotalRevenueCard title="Donation Received From your page" symbol="$" query="Rp" />
                        </Col>

                        <Col xl={10} lg={10} md={10} sm={12} xs={24}>

                            <TotalOrderRevenueCard title="Total Orders" />
                        </Col>
                    </Row>

                    {/* <Row>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                            <IconWithTextCard icon="revenue-new" iconColor="green" title="$2,380" subTitle="Completed Order Amount" />
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                            <IconWithTextCard icon="revenue-new" iconColor="geekblue" title="$2,380" subTitle="InProcess Order Amount" />
                        </Col>

                    </Row> */}

                </Col>


                <Col xl={12} lg={12} md={16} sm={24} xs={24}>
                    <Widget>
                        <RecentActivity recentList={recentActivity} shape="circle" />
                    </Widget>

                </Col>
            </Row>
        </Layout>
    )
}

export default Dashboard

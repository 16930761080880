
const INIT_STATE = {
    restaurants: [],
    products: [],
    restaurantStatus: false,
    
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'restaurantUpdateStatusInfo_SUCCESS': {
            const RestaurantNew = getRestaurantUpdate(state.restaurants, action.payload);
            return { ...state, restaurants: RestaurantNew, [`restaurantStatus_${action.payload.restaurantId}`]: action.payload.status};
        }
        case 'getAllRestaurant_SUCCESS': {
            return { ...state, restaurants: action.payload.Record };
        }
        case 'getAllRestaurantProduct_SUCCESS': {
            const filter = state.restaurants.filter(Item => Item._id === action.reqData.query);
            const data = filter[0]
            console.log("========= filter =============",data);
            return { ...state, products: action.payload.Record,   [`restaurantStatus_${data && data._id}`]: data && data.restaurantStatus };
        }

        default:
            return state;
    }
}


function getRestaurantUpdate(restaurants, data) {
    let newRestaurant = [];
    restaurants.map(Item => {
        if (Item._id === data.restaurantId) {
            Item.restaurantStatus = data.status;
        }
        newRestaurant.push(Item);
        return Item;
    })
    return newRestaurant;
}
import React from 'react'
import { List } from 'antd';
import moment from 'moment';
const Index = (props) => {
     return (
        <List
            dataSource={props.data}
            renderItem={(item, index) => {
                return (
                    <List.Item key={index}>
                        <List.Item.Meta
                            title={<span style={{ color: '#848484', fontWeight: 'bold', fontSize: '12px' }}>{item.donner.displayName}</span>}
                            description={<span style={{ color: '#848484', fontSize: '12px' }}>{moment(item.donner.commentDate).format('Do MMMM, YYYY h:mm a')}</span>}
                        />
                        <div>{item.careCenterAmount}</div>
                    </List.Item>
                )

            }
            }
        >
        </List>
    )
}

export default Index

import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Rp from 'assets/homepage/nathalia-segato-wKHod59AAhE-unsplash.jpg'
import Rh from 'assets/homepage/nathalia-segato-wKHod59AAhE-unsplash.jpg'
import { useLocation, useHistory } from 'react-router-dom'
const Index = ({ deviceType, data, type }) => {

    const { pathname } = useLocation();
    const history = useHistory();
    console.log("========== location ========", pathname, pathname.includes("restaurants"));


    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            paritialVisibilityGutter: 60
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            paritialVisibilityGutter: 50
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            paritialVisibilityGutter: 30
        }
    };


    let defaultImg = Rp;
    if (type === "Rh") {
        defaultImg = Rh;
    }

    const openDetailPage = (name, data) => {
        let pathUrl = `/careCenters/${name}`;
        if (pathname.includes("restaurants")) {
            pathUrl = `/restaurants/${name}`;
        }
        history.push(pathUrl,  { data, name });
    }

    return (
        <Carousel
            ssr
            partialVisbile
            deviceType={deviceType}
            itemClass="image-item"
            responsive={responsive}
        >
            {data.map(Item => {
                return (
                    <div className="sliderImg gx-pointer" onClick={() => openDetailPage(Item.name, Item._id)}>
                        <img
                            draggable={false}
                            style={{ width: "100%", height: "100%" }}
                            src={Item.frontImageUrl ? Item.frontImageUrl : defaultImg}
                            alt=""
                        />
                        <span className="Title">{Item.name}</span>
                    </div>
                );
            })}

        </Carousel>
    )
}

export default Index

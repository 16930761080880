import React from 'react'
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux'
import { DeletedRecord } from 'redux/actions/Common'
const { confirm } = Modal;
const ROOT_URL = process.env.REACT_APP_ROOT_URL;

const DeleteConfirm = ({ title, content, buttonName, selectedItem, type }) => {

    const dispatch = useDispatch();

    const showDeleteConfirm = () => {
        confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            content: content,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            confirmLoading: true,
            onOk() {
                return new Promise((resolve, reject) => {
                    const token = localStorage.getItem('token');
                    fetch(`${ROOT_URL}/${type}`, {
                        method: "DELETE",
                        headers: new Headers({
                            "Content-Type": "application/json",
                            'Authorization': token,
                        }),
                        body: JSON.stringify(selectedItem)
                    }).then((response) => {
                        response.json().then((response) => {
                            dispatch(DeletedRecord(type, response.Record._id))
                            resolve();
                        })
                    }).catch(err => {
                        reject();
                    })

                }).catch(() => console.log('Oops errors!'));

            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }


    return (<span className="gx-link gx-pointer deleteButton" onClick={showDeleteConfirm} > {buttonName} </span>)
}

export default DeleteConfirm

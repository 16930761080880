const SocketResponseState = require('../redux/actions/socket').SocketResponseState;
const io = require("socket.io-client");


const Socket = (function () {
    let instance;

    function createInstance(userId) {
        const socket = io(process.env.REACT_APP_ROOT_URL, { query: `clientId=${userId}` });
        socket.on("connect", function (err, data) {
            console.log("===================== Connection Created", err, data);
        });


        socket.on("newOrderCerated", function (eventName, data) {
            SocketResponseState('newOrderIsCreated', data)
        })

        socket.on("orderEdit", function (eventName, data) {
            SocketResponseState('editOrder', { Record: data })
        })

        socket.on("newActivity", function (eventName, data) {
            SocketResponseState('newActivity', { Record: data })
        })
        socket.on("getPendingRequest", function (data) {
            SocketResponseState('getPendingRequest', { Record: data })
        })
        socket.on("restaurantUpdateStatusInfo", function (data) {
            SocketResponseState('restaurantUpdateStatusInfo', data )
        })



        socket.on("disconnect", () => console.log("Client disconnected"));

        function fetchOrder() {
            return new Promise((resolve, reject) => {
                socket.emit("orderCreated", { "id": "testDAta" });
                resolve();
            });
        }

        function onConnect(userId) {
            return new Promise((resolve, reject) => {
                socket.emit("connect", { "id": userId });
                resolve();
            });
        }


        function socketDisconnet() {

            return new Promise((resolve, reject) => {
                socket.emit("disconnect", {});
                resolve();
            });


        }


        return {
            onConnect,
            fetchOrder,
            socketDisconnet,
        };

    }

    return {
        getInstance: function (userId) {
            if (!instance) {
                instance = createInstance(userId);
            }
            return instance;
        }
    };
})();

module.exports = {
    Socket: Socket
};
import React from "react";
import Widget from "components/Widget/index";
import { Col, Row } from "antd";

const Portfolio = ({ TotalBalance }) => {
  return (
    <Widget styleName="profileCard">
      <h2 className="h4 gx-mb-3">Total Donation Balance</h2>
      <Row>
        <Col lg={12} md={12} sm={12} xs={24}>

          <div className="gx-flex-row">
            <h2 className="gx-mr-2 gx-mb-0 gx-fs-xxxl gx-font-weight-medium gx-mb-3">${TotalBalance}</h2>
            {/* <h4 className="gx-pt-2 gx-chart-up">64% <i className="icon icon-menu-up gx-fs-sm" /></h4> */}
          </div>
          <p className="gx-text-grey">Overall balance</p>
          {/* <div className="gx-flex-row gx-mb-3 gx-mb-md-2">
            <Button className="gx-mr-2 gx-pt-0" type="primary">Deposit</Button>
            <Button className="gx-btn-cyan gx-pt-0">Withdraw</Button>
          </div> */}

          {/* <p className="gx-text-primary gx-pointer gx-d-none gx-d-sm-block gx-mb-1">
            <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle"/> Add New Wallet</p> */}
        </Col>
        {/* <Col lg={12} md={12} sm={12} xs={24}>
          <div className="gx-site-dash">
            <h5 className="gx-mb-3">Portfolio Distribution</h5>
            <ul className="gx-line-indicator gx-fs-sm gx-pb-1 gx-pb-sm-0">
              <li>
                <LineIndicator width="78%" title="CareCenter" title2="8.74" color="primary" value="78%"/>
              </li>
              <li>
                <LineIndicator width="38%" title="Restaurant" title2="1.23" color="pink" value="48%"/>
              </li>
              
            </ul>
           
          </div>
        </Col> */}
      </Row>
    </Widget>
  );
};

export default Portfolio;

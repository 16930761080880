import React, { useState } from 'react';
import { Modal, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DataEntryModalAction } from 'redux/actions/Common'
import { DataRequestAction, updateAllState } from 'redux/actions/http'
import Tags from 'components/Tags'
interface Values {
    title: string;
    description: string;
    modifier: string;
}

interface CollectionCreateFormProps {
    visible: boolean;
    onCreate: (values: Values) => void;
    onCancel: () => void;
}

const CollectionCreateForm: React.FC<CollectionCreateFormProps> = ({
    visible,
    initialValues,
    loader,
    tags,
    setTags,
    title,
    onCreate,
    onCancel,
}) => {
    const [form] = Form.useForm();
    const authProps = useSelector(state => state.auth);
    console.log("============ authProps ======", authProps);
    return (
        <Modal
            visible={visible}
            title={`${initialValues === null ? `Add` : 'Edit'} ${title}`}
            className="formModal"
            okText={`${initialValues === null ? 'Add' : 'Update'}`}
            cancelText="Cancel"
            confirmLoading={loader}
            onCancel={() => { onCancel() }}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        form.resetFields();
                        onCreate(values);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={initialValues}
            >
                <Form.Item
                    name="name"
                    label="Name"
                    defaultValue={initialValues && initialValues.title}
                    rules={[{ required: true, message: 'Please input the title of collection!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="businessType"
                    label="Type"
                    defaultValue={initialValues && initialValues.title}
                    rules={[{ required: true, message: 'Please input the title of collection!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="phoneNumber"
                    label="Phone Number"
                    defaultValue={initialValues && initialValues.title}
                    rules={[{ required: true, message: 'Please input the title of collection!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="email"
                    label="Email"
                    readOnly
                    defaultValue={initialValues && initialValues.title}
                >
                    <Input readOnly />
                </Form.Item>
                <Form.Item
                    name="location"
                    label="Location"
                    defaultValue={initialValues && initialValues.title}
                    rules={[{ required: true, message: 'Please input the title of collection!' }]}
                >
                    <Input />
                </Form.Item>
                {authProps.LogeInUser.role === "careCenter" && <Form.Item
                    name="noEmployee"
                    label="Number of Employee"
                    defaultValue={initialValues && initialValues.title}
                    rules={[{ required: true, message: 'Please input the Number of Employee' }]}
                >
                    <Input type="number" />
                </Form.Item>}

                {authProps.LogeInUser.role === "careCenter" && <Form.Item
                    name="department"
                    label="Department (insert comma separated name. e.g name1,name2,name3)"
                    defaultValue={initialValues && initialValues.title}
                    rules={[{ required: true, message: 'Please input the Department' }]}
                >
                    <Input />
                </Form.Item>
                }
                <Form.Item
                    name="situation"
                    label="Situation update"
                    defaultValue={initialValues && initialValues.title}
                    rules={[{ required: true, message: 'Please input the title of collection!' }]}
                >
                    <Input />
                </Form.Item>

                <Tags tags={tags} setTags={setTags} maxTags={5} />
            </Form>
        </Modal>
    );
};

const ProfileModal = ({ type, title, initialValues }) => {

    const [tags, setTags] = useState([]);
    const dispatch = useDispatch();
    const profileProps = useSelector(state => state.profile)
    const onCreate = values => {
        values.tags = tags;
        dispatch(DataRequestAction('POST', 'updateProfile', values, '', 'profile', false));
    };

    if (profileProps.success) {
        dispatch(updateAllState('profile'));
    }

    if (initialValues && initialValues.tags.length > tags.length) {
        setTags(initialValues.tags);
    }

    return <CollectionCreateForm
        visible={profileProps.modalVisible}
        loader={profileProps.profileLoader}
        initialValues={initialValues}
        tags={tags}
        setTags={setTags}
        title={title}
        onCreate={onCreate}
        onCancel={() => dispatch(DataEntryModalAction(type, null))}
    />

};

export default ProfileModal;
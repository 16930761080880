const MenuList = {
    "restaurant": [{
        "title": "Dashboard",
        "url": "/restaurant/dashboard",
        "icon": "dasbhoard"
    },
    {
        "title": "Food Category",
        "url": "/restaurant/category",
        "icon": "wysiwyg"
    },
    {
        "title": "Products",
        "url": "/restaurant/products",
        "icon": "shopping-cart"
    },
    {
        "title": "Orders",
        "url": "/restaurant/orders",
        "icon": "orders"
    },
    {
        "title": "Transactions",
        "url": "/restaurant/transactions",
        "icon": "card"
    },
    {
        "title": "Front page",
        "url": "/restaurant/frontPage",
        "icon": "data-display"
    }
    ],
    "careCenter": [{
        "title": "Dashboard",
        "url": "/careCenter/dashboard",
        "icon": "dasbhoard"
    },
    {
        "title": "Restaurants",
        "url": "/careCenter/restaurants",
        "icon": "burger"
    },
    {
        "title": "Order",
        "url": "/careCenter/orders",
        "icon": "orders"
    },
    {
        "title": "Front Page",
        "url": "/careCenter/frontPage",
        "icon": "data-display"
    }],
    "superAdmin": [
        {
            "title": "Dashboard",
            "url": "/admin/dashboard",
            "icon": "dasbhoard"
        },
        {
            "title": "Restaurants",
            "url": "/admin/restaurants",
            "icon": "burger",
            "badge":"restaurants"
        },
        {
            "title": "Care Center",
            "url": "/admin/careCenter",
            "icon": "company",
            "badge":"careCenters"
        },
        {
            "title": "Restaurant Type",
            "url": "/admin/category",
            "icon": "company"
        },
        {
            "title": "Donations",
            "url": "/admin/donations",
            "icon": "revenue-new"
        },
        {
            "title": "Orders",
            "url": "/admin/orders",
            "icon": "orders",
        },
        {
            "title": "Transactions",
            "url": "/admin/transactions",
            "icon": "card"
        }
    ],
        
}

export default MenuList;
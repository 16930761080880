import React, { useEffect } from 'react'
import { Card, Button } from "antd";
import { useDispatch, useSelector } from 'react-redux'
import { DataEntryModalAction } from 'redux/actions/Common'
import Layout from 'components/LayoutForPortal'
import Categories from 'components/Table/BasicTable'
import CategoryForm from './components/category/categoryForm'
import DeleteConfirm from 'components/Modal/DeleteConfirm'
import { DataGetAction } from 'redux/actions/http'

const Category = () => {

    const columns = [
        {
          title: 'Category name',
          dataIndex: 'title',
          width:200
        },
     
        {
          title: 'Actions',
          render: (record) => <div><span className="gx-link gx-pointer" onClick={() => showModal(record)}>Edit</span> | <DeleteConfirm type="deleteCategory" selectedItem={record} buttonName="Delete" title="Are you sure delete this task?" content="" /></div>,
          width:200
        },
      ];
    
      const dispatch = useDispatch();
      const catProps = useSelector(state => state.category);
      const modalType = "show_category_modal";
    
      const showModal = (Item) => {
        dispatch(DataEntryModalAction(modalType, Item));
      }
    
      const fetchCategory = () => {
        dispatch(DataGetAction('getCategory', { query: "all" }));
      }
      useEffect(fetchCategory, [])
    
      return (
        <Layout>
          <Card title={<h2>Categories</h2>} extra={<Button type="primary" onClick={() => showModal(null)} className="gx-d-flex gx-flex-column gx-justify-content-center gx-mb-0">Add Category</Button>}>
            <Categories columns={columns} data={catProps.Categories} />
          </Card>
          <CategoryForm
            type={modalType}
            title="category"
          />
        </Layout>
      )
}

export default Category

import React, { useEffect } from 'react';
import { Form, Input, Button, Spin, message } from 'antd';
import { SimpleModalAction } from 'redux/actions/Common';
import { DataRequestAction, updateAllState } from 'redux/actions/http';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import alerts from 'alerts'

const SignIn = (props) => {

    const authProps = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const history = useHistory();

    const OpenSignUpModal = () => {
        dispatch(updateAllState('auth'))
        dispatch(SimpleModalAction('login', false))
        dispatch(SimpleModalAction('signUp', true))
    }
    const OpenForgotModal = () => {
        dispatch(updateAllState('auth'))
        dispatch(SimpleModalAction('login', false))
        dispatch(SimpleModalAction('forgotPassword', true))
    }

    if (authProps.loginSuccess) {
        dispatch(SimpleModalAction('login', false))
        dispatch(SimpleModalAction('signUp', false))

        
        const LogeInUser = authProps.LogeInUser
        
        if (LogeInUser && LogeInUser.role === "restaurant") {
            history.push('/restaurant/dashboard');
        } else if (LogeInUser && LogeInUser.role === "careCenter") {
            history.push('/careCenter/dashboard');
        } else if (LogeInUser && LogeInUser.role === "superAdmin") {
            history.push('/admin/dashboard');
        }

        message.success(`${alerts.Login_Success} ${LogeInUser.name}`)

    }

    const LoadPage = () => {
        return () => {
            dispatch(updateAllState('auth'));
        }
    }
    useEffect(LoadPage, [])

    const onFinish = values => {
        dispatch(DataRequestAction('POST', 'login', values, '', 'login', false));
    };

    return (
        <div className="SignIn gx-p-5">

            <div className="welcomeMessage gx-text-center gx-mb-5">
                <h2 className="gx-font-weight-bold gx-mb-0">Welcome back</h2>
                <p className="gx-mb-0">Login to your account</p>
                <p className={`gx-text-center ${authProps.error ? "error" : ""}`}> {authProps.message}</p>
            </div>

            <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Please input your Email!' }]}
                >
                    <Input placeholder="Email address" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your Password!' }]}
                >
                    <Input
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>
                <Form.Item>
                    <span className="login-form-forgot gx-float-right gx-pointer gx-link" style={{ color: '#ADB1C9' }} onClick={() => OpenForgotModal()}> Forgot password </span>
                </Form.Item>

                <Form.Item>
                    {!authProps.ShowLoginLoader && <Button type="primary" htmlType="submit" className="login-form-button gx-d-flex gx-w-100 gx-flex-row gx-justify-content-center" style={{ lineHeight: '0px' }}>
                        Log in
                 </Button>}
                    {authProps.ShowLoginLoader && <Spin><Button type="primary" htmlType="button" className="login-form-button gx-d-flex gx-w-100 gx-flex-row gx-justify-content-center" style={{ lineHeight: '0px' }}>
                        Log in
                 </Button></Spin>}
                </Form.Item>
                <p className="gx-text-center" onClick={() => OpenSignUpModal()}>New here? <span className="gx-pointer gx-link">Sign up</span></p>
            </Form>
        </div>
    );
};

export default SignIn;
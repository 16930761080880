import React, { useEffect } from 'react'
import { Row, Col } from 'antd';
import Nav from 'components/Navigation'
import Footer from 'components/Footer'
import DonationBox from 'containers/Donation'
import RestaurantMain from 'containers/RestaurantMain'
import InfoSection from 'containers/InfoSection'
import CommentSection from 'containers/CommentSection'
import SideBarCard from 'containers/SideBarCard';
import Slider from 'containers/Slider'
import { useDispatch, useSelector } from 'react-redux';
import { DataGetAction } from 'redux/actions/http'
import { FacebookShareButton, TwitterShareButton, EmailShareButton } from "react-share";
import { TwitterSquareFilled, FacebookFilled, MailFilled } from '@ant-design/icons'
import { useLocation } from 'react-router-dom'
const Index = () => {

    const dispatch = useDispatch();
    const ResProps = useSelector(state => state.user.publicProfile);
    const location = useLocation();
   
    let id = location.state && location.state.data;
    
    const fetchPublicProfile = () => {
        dispatch(DataGetAction('getPublicProfile', { query: "Rp", id }));
        window.scrollTo(0,0);
    }

    useEffect(fetchPublicProfile, [id]);

    return (
        <Row>
            <Col xl={24} md={24} sm={24} xs={24}>
                <Nav />
            </Col>
            <Col className="bannerSection" style={{ paddingTop: '80px' }} xl={24} md={24} sm={24} xs={24}>
                <div className="MainSection gx-mt-5">
                    <div className="gx-container">
                        <Row>
                            <Col xl={18} md={18} sm={24} xs={24}>
                                <h2 className="gx-font-weight-bold" style={{ textTransform: 'capitalize' }}>{ResProps ? ResProps.name : ""}     </h2>
                            </Col>
                            <Col xl={6} md={6} sm={24} xs={24}>
                                <div className="ShareButtons" style={{
                                    float: 'right',
                                    marginRight: '29px'
                                }}>
                                    <FacebookShareButton url="https://ub4i.digitalsensei.net/restaurant" >
                                        <span className="shareButton facebook">
                                            <FacebookFilled className="socialFooter" />
                                            Share it!
                                        </span>
                                    </FacebookShareButton>
                                    <TwitterShareButton url="https://ub4i.digitalsensei.net/restaurant" >
                                        <span className="shareButton twitter">
                                            <TwitterSquareFilled className="socialFooter" />
                                            Tweet!
                                        </span>
                                    </TwitterShareButton>
                                </div>
                            </Col>
                        </Row>
                        <Row className="gx-mr-2">
                            <Col xl={18} md={18} sm={24} xs={24}>
                                <RestaurantMain ResProps={ResProps} />
                            </Col>
                            <Col xl={6} md={6} sm={24} xs={24}>
                                <DonationBox showDonation={true} id={id} page="restaurant"/>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
            <Col xl={24} md={24} sm={24} xs={24} className="whiteBackground">
                <div className="gx-container gx-pl-0 gx-mt-5 gx-pb-5">
                    <Row className="gx-mr-2 gx-ml-0">
                        <Col xl={18} md={18} sm={24} xs={24} className="gx-pr-3">
                            <InfoSection title="About" desc={ResProps ? ResProps.about : ""} />

                            <CommentSection title="Comments" />

                            <InfoSection title="Updates" desc={ResProps ? ResProps.updates : ""} />

                            <div className="helpSpread">
                                <h2>Help spread the world</h2>
                                <div className="SharedButton">
                                    <FacebookShareButton url="https://ub4i.digitalsensei.net/restaurant" >
                                        <span className="shareButton facebook">
                                            <FacebookFilled className="socialFooter" />
                                            Share it!
                                        </span>
                                    </FacebookShareButton>
                                    <TwitterShareButton url="https://ub4i.digitalsensei.net/restaurant" >
                                        <span className="shareButton twitter">
                                            <TwitterSquareFilled className="socialFooter" />
                                            Tweet!
                                        </span>
                                    </TwitterShareButton>
                                    <EmailShareButton url="https://ub4i.digitalsensei.net/restaurant" >
                                        <span className="shareButton email">
                                            <MailFilled className="socialFooter" />
                                            Email!
                                        </span>
                                    </EmailShareButton>

                                </div>
                            </div>
                        </Col>
                        <Col xl={6} md={6} sm={24} xs={24}>
                            <SideBarCard title="Care centers donating to" data={[]} />
                            <div className="sideBarDonationBox gx-mt-5">
                                <DonationBox showDonation={false} styledName="SideBarDonation" id={id} page="restaurant"/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col xl={24} md={24} sm={24} xs={24} className="sliderContainer">
                <div className="gx-container gx-p-0">
                    <Slider ResProps={ResProps && ResProps._id} fetch={"Rp"} title="Other restaurants" />
                </div>

            </Col>


            <Col xl={24} md={24} sm={24} xs={24} className="footerContainer">
                <Footer />
            </Col>
        </Row >
    )
}

export default Index
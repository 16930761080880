import React from 'react'
import { Row, Col, Button } from 'antd';
import Nav from 'components/Navigation'
import Footer from 'components/Footer'
import BannerSection from 'components/Banner'
import Service from 'components/Service'
import { useDispatch } from 'react-redux'
import { SimpleModalAction } from 'redux/actions/Common'


import Res from '../../assets/homepage/fast-food.svg';
import hosp from '../../assets/homepage/hospital.svg';
import speed from '../../assets/homepage/speed.svg';
import hp2 from '../../assets/homepage/adhy-savala-zbpgmGe27p8-unsplash.jpg';
import hp1 from '../../assets/homepage/3488995.jpg';
import r1 from '../../assets/homepage/micheile-henderson-micheile010-visual-stories-nl-eB3Pi5-U8l8-unsplash.jpg';
import r2 from '../../assets/homepage/nathalia-segato-wKHod59AAhE-unsplash.jpg';
import r3 from '../../assets/homepage/tomato-burger-and-fried-fries-1600727.jpg';

import HowCanYouHelp from 'assets/homepage/howCanHelp.svg'
import HowCanYouHelpBg from 'assets/homepage/Bg.svg'

const Index = () => {


  const dispatch = useDispatch();
  const openDonationModal = () => {
    dispatch(SimpleModalAction('Donation', true));
  }

  return (
    <Row>
      <Col xl={24} md={24} sm={24} xs={24}>
        <Row className="BannerImg">
          <Col xl={24} md={24} sm={24} xs={24}>
            <Nav />
          </Col>
          <Col xl={24} md={24} sm={24} xs={24}>
            <BannerSection openDonationModal={openDonationModal}/>
          </Col>
        </Row>
      </Col>
      <Col xl={24} md={24} sm={24} xs={24}>
        <div className="howItsWork gx-container gx-mt-5">
          <h1 className="gx-text-center gx-font-weight-bold gx-mb-5 gx-pb-4">How Ub4i works</h1>
          <Row>
            <Col xl={8} md={8} sm={24} xs={24}>
              <Service Item={{
                bg: "#2C4BFF",
                bxs: "#2C4BFF4D",
                imgUrl: Res,
                title: "Choose local restaurant to support",
                desc: "Pick your favorite restaurant and donate food to frontline workers."
              }} />
            </Col>
            <Col xl={8} md={8} sm={24} xs={24}>
              <Service Item={{
                bg: "#FAB005",
                bxs: "#FAB0054D",
                imgUrl: hosp,
                title: "Choose care center to feed",
                desc: "Pick care center and help us in sending food to hospitals in this critical situation."
              }} />
            </Col>
            <Col xl={8} md={8} sm={24} xs={24}>
              <Service Item={{
                bg: "#F03E3D",
                bxs: "#F03E3D4D",
                imgUrl: speed,
                title: "Meals get delivered to care center",
                desc: "We will provide food to care centers from the donations we raised with your help."
              }} />
            </Col>
          </Row>
        </div>
      </Col>
      <Col xl={24} md={24} sm={24} xs={24}>
        <div className="howCanHelp gx-container gx-text-center gx-mt-5" style={{
          background:`url(${HowCanYouHelpBg}) top no-repeat`
        }}>
          <h1 className="gx-text-center gx-font-weight-bold ">How you can help</h1>
          <p className="h2 gx-mb-5 gx-pb-4">Show your support and help everyone during these times</p>
          <Row>
            <Col xl={24} md={24} sm={24} xs={24} className="gx-d-flex gx-m-auto">
              <img src={HowCanYouHelp} alt="howCanYouHelp" style={{
                maxWidth: '1100px',
                margin: '0 auto',
                width:'100%'
              }} />
            </Col>
          </Row>
        </div>
      </Col>
      <Col xl={24} md={24} sm={24} xs={24} className="comeTogether">
        <div className=" gx-container gx-text-center gx-mt-5 gx-pb-5">
          <p className="h2 gx-mt-5 " style={{ color: '#FFFFFF' }}>Lets come together and support each other</p>
          <Button className="ant-btn whiteButton gx-d-flex gx-flex-column gx-justify-content-center gx-m-auto" onClick={() => { openDonationModal() }}>Donate Now</Button>
        </div>
      </Col>
      <Col xl={24} md={24} sm={24} xs={24} className="featured">
        <div className=" gx-container gx-text-center gx-mt-5 gx-pb-5">
          <p className="h1 gx-mt-5 gx-font-weight-bold " style={{ color: '#000000' }}>Featured restaurants and care centers</p>
          <div className="featureImages">
            <Row>
              <Col xl={8} md={8} sm={24} xs={24} className="featureBox gx-mb-3">
                <img src={hp1} alt="careCenter" />
              </Col>
              <Col xl={16} md={16} sm={24} xs={24} className="featureBox gx-mb-3">
                <img src={hp2} alt="careCenter" style={{
                  height: '245px',
                  width: '100%'
                }} />
              </Col>
            </Row>
            <Row>
              <Col xl={8} md={8} sm={24} xs={24} className="featureBox gx-mb-3">
                <img src={r1} alt="Restaurant" />
              </Col>
              <Col xl={8} md={8} sm={24} xs={24} className="featureBox gx-mb-3">
                <img src={r2} alt="Restaurant" />
              </Col>
              <Col xl={8} md={8} sm={24} xs={24} className="featureBox gx-mb-3">
                <img src={r3} alt="Restaurant" />
              </Col>
            </Row>
          </div>

        </div>
      </Col>
      <Col xl={24} md={24} sm={24} xs={24}>
        <Footer />
      </Col>

    </Row>
  )
}
export default Index;
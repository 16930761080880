

const INIT_STATE = {
  token: localStorage.getItem('token'),
  initURL: '',
  ShowSignUpLoader: false,
  ShowLoginLoader: false,
  authUser: JSON.parse(localStorage.getItem('user')),
  success: false,
  error: false,
  LogeInUser: null,
  loginSuccess: false,
  ShowRestLoader: false,
  logoutSuccess: false,
  ShowSetNewLoader: false,
  ShowForgotLoader: false,
  signUpSuccess: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case 'INIT_URL': {
      return { ...state, initURL: action.payload };
    }

    case 'signup_START': {
      return { ...state, ShowSignUpLoader: true };
    }
    case 'signup_STOP': {
      return { ...state, ShowSignUpLoader: false };
    }

    case 'login_START': {
      return { ...state, ShowLoginLoader: true };
    }
    case 'login_STOP': {
      return { ...state, ShowLoginLoader: false };
    }

    case 'reset_START': {
      return { ...state, ShowRestLoader: true };
    }
    case 'reset_STOP': {
      return { ...state, ShowRestLoader: false };
    }
    case 'forgot_START': {
      return { ...state, ShowForgotLoader: true };
    }
    case 'forgot_STOP': {
      return { ...state, ShowForgotLoader: false };
    }

    case 'setNew_START': {
      return { ...state, ShowSetNewLoader: true };
    }
    case 'setNew_STOP': {
      return { ...state, ShowSetNewLoader: false };
    }


    case 'updateRestaurantStatus_SUCCESS': {
      const LoginUser = state.LogeInUser;
      LoginUser.restaurantStatus = action.payload.Record.restaurantStatus;
      return { ...state, LogeInUser: LoginUser }
    }


    case 'login_SUCCESS': {
      localStorage.setItem('token', action.payload.user.token);
      return { ...state, logoutSuccess: false, LogeInUser: action.payload.user, ShowSignUpLoader: false, error: false, loginSuccess: true, message: "" };
    }

    case 'logout_success': {

      localStorage.clear();
      localStorage.setItem('persist:root', null);
      return {
        ...state,
        initURL: '',
        LogeInUser: null,
        token: localStorage.getItem('token'),
        logoutSuccess: true,
        ShowSignUpLoader: false,
        authUser: JSON.parse(localStorage.getItem('user')),
        loginSuccess: false,
        ShowLoginLoader: false,
        error: false,
        success: false,
        message: ""
      };
    }
    case 'login_FAILURE': {
      return { ...state, ShowSignUpLoader: false, error: true, success: false, message: action.payload };
    }




    case 'signup_SUCCESS': {
      return { ...state, ShowSignUpLoader: false, error: false, signUpSuccess: true, success: true, message: "You are Successfully Register." };
    }
    case 'signup_FAILURE': {
      return { ...state, ShowSignUpLoader: false, error: true, signUpSuccess: false, success: false, message: "Some Error Occur While Registering. try again" };
    }

    case 'forgetPassword_SUCCESS': {
      return { ...state, ShowForgotLoader: false, error: false, success: true, message: "You are Successfully Reset Password." };
    }
    case 'forgetPassword_FAILURE': {
      return { ...state, ShowForgotLoader: false, error: true, success: false, message: action.payload.message };
    }
    case 'resetPassword_SUCCESS': {
      return { ...state, ShowRestLoader: false, error: false, success: true, message: "You are Successfully Reset Password." };
    }
    case 'resetPassword_FAILURE': {
      return { ...state, ShowRestLoader: false, error: true, success: false, message: action.payload.message };
    }

    case 'setNewPassword_SUCCESS': {
      return { ...state, ShowSetNewLoader: false, error: false, success: true, message: "You are Successfully set new Password." };
    }
    case 'setNewPassword_FAILURE': {
      return { ...state, ShowSetNewLoader: false, error: true, success: false, message: "Some Error occur while set new password. try again" };
    }

    case 'auth_UPDATE': {
      return { ...state, signUpSuccess: false, ShowForgotLoader: false, ShowSetNewLoader: false, ShowSignUpLoader: false, ShowRestLoader: false, loginSuccess: false, logoutSuccess: false, ShowLoginLoader: false, error: false, success: false, message: "" };
    }

    default:
      return state;
  }
}

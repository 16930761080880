import React from 'react';
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from "react-router-dom";
import "assets/vendor/style";
import "./styles/index.less";
import store, { history, persisStore } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react'
import App from "./containers/App/index";



const MainApp = () =>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persisStore}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </ConnectedRouter>
    </PersistGate>
  </Provider>;

export default MainApp;
import React, { useEffect } from 'react'
import Layout from 'components/LayoutForPortal'
import { Card, Row, Col } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { DataGetAction } from 'redux/actions/http'
import RestaurantItem from './components/restaurantItem'
import Search from 'components/Filters/SearchFilter'
const Restaurant = () => {

    const dispatch = useDispatch();
    const restaurantProps = useSelector(state => state.restaurant);

    const fetchRestaurant = () => {
        dispatch(DataGetAction('getAllRestaurant', { "query": "all", "data": "hp" }));
    }

    useEffect(fetchRestaurant, [])

    const { restaurants } = restaurantProps;

    const renderRestaurant = () => {
        if (restaurants) {
            return restaurants.map((Item, index) => {
                return (
                    <Col key={index} xl={6} md={8} sm={12} xs={24}>
                        <RestaurantItem key={index} product={Item} />
                    </Col>
                )
            })
        }
    }

    return (
        <Layout>
            <Card title={<h2>Restaurants</h2>} extra={
                <Search
                placeholder={`Search by name`}
                searchByValueChange={true}
                searchByClick={true}
                maxValueLengthForStartSearch={3}
                type="getAllRestaurant"
                query={{ query: "Rp", searchBy: "name", search: "" }}
              />
            }>
                <Row className="gx-m-0">
                    {renderRestaurant()}
                </Row>
            </Card>
        </Layout>
    )
}

export default Restaurant

import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DataEntryModalAction } from 'redux/actions/Common';

const CartDetail = () => {

    const dispatch = useDispatch();
    const cartProps = useSelector(state => state.cart);
    const modalType = "show_cart_detail_modal";


return (<span className="cart" onClick={() =>  { dispatch(DataEntryModalAction(modalType, null))}}><i className="icon icon-shopping-cart"></i><span className="badge">{cartProps.cartQty}</span></span>)
}

export default CartDetail

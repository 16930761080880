import _ from 'lodash';

const INIT_STATE = {
    Users: [],
    success: false,
    error: false,
    publicProfile: null,
    RecentDonations: null,
    others: [],
    hospitals: [],
    AllHp: [],
    ProductList:[],
    pendingRequest:[],
    AllHpp:[]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {


        case 'getPendingRequest_SUCCESS': {
            return { ...state, pendingRequest: action.payload.Record }
        }
        case 'getProductList_SUCCESS': {
            return { ...state, ProductList: action.payload.Record }
        }
        case 'getAllHospital_SUCCESS': {
            return { ...state, AllHp: action.payload.Record }
        }
        case 'getAllHpp_SUCCESS': {
            return { ...state, AllHpp: action.payload.Record }
        }
        case 'fetchOtherRH_SUCCESS': {
            return { ...state, others: action.payload.Record }
        }
        case 'getPublicProfileForRh_SUCCESS': {
            return { ...state, hospitals: action.payload.Record }
        }
        case 'getSingleDonation_SUCCESS': {
            return { ...state, RecentDonations: action.payload.Record };
        }
        
        case 'getRecentDonation_SUCCESS': {
            return { ...state, RecentDonations: action.payload.Record }
        }
        case 'getPublicProfile_SUCCESS': {
            return { ...state, publicProfile: action.payload.Record }
        }

        case 'getAllUsers_SUCCESS': {
            return { ...state, Users: action.payload.Record };
        }
        case 'editUser_SUCCESS': {
            const Record = action.payload.Record;
            const oldList = state.Users;
            const newList = _.map(oldList, x => {
                if (x._id === Record._id) {
                    return Record
                } else {
                    return x
                }
            })

            return { ...state, Users: newList, success: true };
        }
        case 'editUser_FAILURE': {
            return { ...state,  error: true };
        }

        case 'User_UPDATE': {
            return { ...state, success: false, error: false }
        }

        default:
            return state;
    }
}

import React, { useEffect } from 'react';
import { Form, Input, Button, Spin, message } from 'antd';
import { DataRequestAction, updateAllState } from 'redux/actions/http';
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const SetNewPassword = (props) => {

    const authProps = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const params = useParams();


    const LoadPage = () => {
        return () => {
            dispatch(updateAllState('auth'));
        }
    }
    useEffect(LoadPage, [])

    const onFinish = values => {
        values.forgetPasswordAuthToken = params.guid;
        dispatch(DataRequestAction('POST', 'setNewPassword', values, '', 'setNew', false));
    };

    if (authProps.success) {
        message.success("Your new password set successfully");
        dispatch(updateAllState('auth'));
    }

    return (
        <div className="SignIn gx-p-5 gx-w-25 gx-m-auto">

            <div className="welcomeMessage gx-text-center gx-mb-5">
                <h2 className="gx-font-weight-bold gx-mb-3">Set New Password </h2>
                <p className={`gx-text-center ${authProps.error ? "error" : ""}`}> {authProps.message}</p>
            </div>

            <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="password"

                    rules={[
                        {
                            required: true,
                            message: 'Please enter your password!',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password placeholder="Password" />
                </Form.Item>

                <Form.Item
                    name="confirm"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'password not match!',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('Password not match!');
                            },
                        }),
                    ]}
                >
                    <Input.Password placeholder="Confirm Password" />
                </Form.Item>


                <Form.Item>
                    {!authProps.ShowSetNewLoader && <Button type="primary" htmlType="submit" className="login-form-button gx-d-flex gx-w-100 gx-flex-row gx-justify-content-center" style={{ lineHeight: '0px' }}>
                        Save
                 </Button>}
                    {authProps.ShowSetNewLoader && <Spin><Button type="primary" htmlType="button" className="login-form-button gx-d-flex gx-w-100 gx-flex-row gx-justify-content-center" style={{ lineHeight: '0px' }}>
                        Save
                 </Button></Spin>}
                </Form.Item>
            </Form>
        </div>
    );
};

export default SetNewPassword;
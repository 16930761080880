import React, { useState } from 'react'
import { Form, Input, Button, Select, Radio, Row, Col, Checkbox, Spin, message } from 'antd';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { DonationSpinner } from 'redux/actions/Common';
import { DataRequestAction, updateAllState } from 'redux/actions/http'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import img1 from 'assets/Payments/ssl.svg'
import img2 from 'assets/Payments/shielded.svg'
import img3 from 'assets/Payments/visa-pay-logo.svg'
const { Option } = Select;

const DonationForm = ({ AllHp, title, titleField, desc, homePage, hospital }) => {

    const strip = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const donationProps = useSelector(state => state.donation);
    const location = useLocation();
    const [form] = Form.useForm();


    const [amountSelected, setAmountSelected] = useState(0);
    const [otherTipAmount, showOtherTipField] = useState(false);
    const [tipGen, setTip] = useState(10);

    const onFinish = values => {

        //location.state.data
        let restaurant = 0;
        let careCenter = 0;
        let page = "careCenter";

        if (location.pathname.includes('home')) {
            restaurant = values.userInfo;
            careCenter = values.careCenter;
            page = "home";
        } else {

            if (location.pathname.includes('restaurants')) {
                restaurant = location.state.data;
                careCenter = values.userInfo
                page = "restaurant"
            } else {
                careCenter = location.state.data;
                restaurant = values.userInfo
            }
        }


        const cardNumber = elements.getElement(CardNumberElement);
        dispatch(DonationSpinner());
        strip.createToken(cardNumber).then(function (result) {
            if (result.error) {
                dispatch(DonationSpinner());
                return
            }


            const tipForUb41 = calculateTip();
            const total = calculateTotal();
            const data = {
                page: page,
                restaurant: restaurant,
                careCenter: careCenter,
                careCenterAmount: amountSelected,
                tip: tipForUb41,
                totalAmount: total, //tipForUb41 + values.careCenterRadio,
                donnerInfo: {
                    fullName: values.fullName,
                    email: values.email,
                    displayName: values.showName,
                    comment: values.comment,
                    hideAmount: values.hideAmount,
                    hideName: values.hideName,
                },
                tok: result.token.id
            }

            dispatch(DataRequestAction('POST', 'donation', data, "", "Donation", false));

        })

    };

    if (donationProps.success) {
        message.success("Thanks for your donations")
        form.resetFields();
        dispatch(updateAllState('donation'))
    }

    const onFinishFailed = errorInfo => {

        console.log('Failed:', errorInfo);
    };

    const tipSelected = (e) => {
        setTip(e)
    }


    const calculateTotal = () => {
        let total = 0.00
        if (otherTipAmount) {
            total = parseInt(amountSelected) + parseInt(tipGen);
        } else {
            const tip = parseFloat(calculateTip());
            total = tip + amountSelected;
        }

        return total.toFixed(2);
    }

    const calculateTip = () => {
        let tip = 0;
        if (otherTipAmount) {
            tip = tipGen;
        } else {
            tip = ((tipGen / 100) * amountSelected).toFixed(2);
        }
        return tip;
    }

    return (
        <div className="gx-mt-2">
            <Form
                layout="horizontal"
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >



                <div className="CareCenterInfo">
                    {homePage !== "homePage" && <h4 className="gx-mb-0">{title}</h4>}
                    {homePage !== "homePage" && <p style={{ color: '#ADB1C9' }}>{desc}</p>}

                    {homePage === "homePage" && <Form.Item
                        label="Select Care Center"
                        name="careCenter"
                        className="gx-pl-2 gx-pt-3"
                        rules={[{ required: true, message: 'Please select at least one' }]}
                    >
                        <Select
                            placeholder="Select Care Center"
                            style={{ width: 230 }}
                            allowClear
                        >
                            {hospital && hospital.map(Item => {
                                return <Option value={Item._id}>{Item.name}</Option>

                            })}
                        </Select>
                    </Form.Item>

                    }


                    <Form.Item
                        label={titleField}
                        name="userInfo"
                        className="gx-pl-2 gx-pt-3"
                        rules={[{ required: true, message: 'Please select at least one' }]}
                    >
                        <Select
                            placeholder={title.includes('Restaurant') ? "Select Care Center" : "Select Restaurant"}
                            style={{ width: 230 }}
                            allowClear
                        >
                            {AllHp && AllHp.map(Item => {
                                return <Option value={Item._id}>{Item.name}</Option>

                            })}
                        </Select>
                    </Form.Item>
                </div>

                <div className="CareCenterInfo gx-mt-4">
                    <h4 className="gx-mb-0">Choose donation amount</h4>
                    <Form.Item
                        label=""
                        name="careCenterRadio"
                        className="gx-pl-2 gx-pt-3"
                    >
                        <Radio.Group defaultValue={amountSelected} buttonStyle="solid" onChange={(e) => { console.log(e); setAmountSelected(e.target.value) }}>
                            <Radio.Button value={5}>$5</Radio.Button>
                            <Radio.Button value={10}>$10</Radio.Button>
                            <Radio.Button value={25}>$25</Radio.Button>
                            <Radio.Button value={100}>$100</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <div className="gx-d-flex gx-flex-row ">
                        <Input
                            value={amountSelected}
                            name="careCenterAmount"
                            onChange={(e) => setAmountSelected(e.target.value)}
                            className="textField"
                            size="large"
                            prefix={<h5 className="gx-d-flex gx-flex-column gx-align-items-center gx-pt-2"><span>$</span><span>USD</span></h5>}
                        />


                    </div>

                </div>

                <div className="CareCenterInfo gx-mt-4">
                    <h4 className="gx-mb-0">Tip Ub4i services</h4>
                    <p style={{ color: '#ADB1C9' }}>
                        Ub4i has a 0% platform fee for organizers relies on the
                        generosity of donors like you to operate our services
                    </p>
                    <Form.Item
                        label="Support us adding tip of:"
                        name="tip"
                        className="gx-pl-2 gx-pt-3"
                    >
                        <Select
                            defaultValue={tipGen}
                            placeholder="Select Tip for UB4i"
                            style={{ width: 260 }}
                            onChange={(e) => {
                                if (e !== "Other") {
                                    showOtherTipField(false)
                                    tipSelected(e)
                                } else {
                                    showOtherTipField(true)
                                }
                            }}
                            allowClear
                        >
                            <Option value={5}>5%</Option>
                            <Option value={10}>10%</Option>
                            <Option value={15}>15%</Option>
                            <Option value={25}>25%</Option>
                            <Option value="Other">Other</Option>

                        </Select>
                    </Form.Item>
                    {otherTipAmount && <Form.Item
                        label=""
                        name="otherAmount"
                        rules={[{ required: true, message: 'Please enter the amount' }]}
                        className="gx-pl-2 gx-pr-2"
                        size="large"
                        onChange={(e) => { console.log("============== Amount ========", e.target.value); setTip(e.target.value) }}

                    >
                        <Input placeholder="Amount" prefix={<h5 className="gx-d-flex gx-flex-column gx-align-items-center gx-pt-2"><span>$</span></h5>} />
                    </Form.Item>}
                </div>

                <div className="CareCenterInfo gx-mt-4">
                    <h4 className="gx-mb-0">Your details</h4>
                    <Form.Item
                        label=""
                        name="fullName"
                        className="gx-pl-2 gx-pt-3 gx-pr-2"
                        rules={[{ required: true, message: 'Please select careCenter' }]}
                    >
                        <Input placeholder="Full name" />
                    </Form.Item>
                    <Form.Item
                        label=""
                        name="email"
                        className="gx-pl-2 gx-pr-2"
                        rules={[{ required: true, message: 'Please select careCenter' }]}
                    >
                        <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        label=""
                        name="showName"
                        className="gx-pl-2 gx-pr-2"
                    >
                        <Input placeholder="Show this name in the donation" />
                    </Form.Item>
                    <Form.Item
                        label=""
                        name="comment"
                        className="gx-pl-2 gx-pr-2"
                    >
                        <Input placeholder="Comment you like to say in donation scroll" />
                    </Form.Item>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Form.Item name="hideAmount" className="gx-ml-1" >
                                <Checkbox>Hide amount</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>

                            <Form.Item name="hideName" >
                                <Checkbox>Don't show my name</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>

                <div className="CareCenterInfo gx-mt-4">
                    <h4 className="gx-mb-0">Billing Information</h4>
                    <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <div className="gx-mt-3 gx-mb-3 cardNumber">
                                <CardNumberElement
                                    options={{
                                        style: {
                                            base: {
                                                border: '1px solid red !important',
                                                '::placeholder': {
                                                    color: '#aab7c4',
                                                },
                                            },
                                            invalid: {
                                                color: '#9e2146',
                                            },
                                        },
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <div className=" cardNumber">
                                <CardExpiryElement
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: '16px',
                                                color: '#424770',
                                                '::placeholder': {
                                                    color: '#aab7c4',
                                                },
                                            },
                                            invalid: {
                                                color: '#9e2146',
                                            },
                                        },
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <div className=" cardNumber">
                                <CardCvcElement
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: '16px',
                                                color: '#424770',
                                                '::placeholder': {
                                                    color: '#aab7c4',
                                                },
                                            },
                                            invalid: {
                                                color: '#9e2146',
                                            },
                                        },
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="gx-mt-3 gx-mb-3 paymentInfo gx-px-3">
                    <h4 className="gx-mt-3">Confirm your donation</h4>
                    <p className="gx-d-flex gx-flex-row gx-justify-content-between"><span>Care Center</span><span>${amountSelected}.00</span></p>
                    <p className="gx-d-flex gx-flex-row gx-justify-content-between"><span>Ub4i tip</span><span>${calculateTip()}</span></p>
                    <p className="gx-d-flex gx-flex-row gx-justify-content-between"><span>Total</span><span>${calculateTotal()}</span></p>
                </div>

                <Form.Item className="gx-px-3">
                    {!donationProps.DonationLoader && <Button type="primary" htmlType="submit" className="gx-pt-0" block>
                        Donate
                                </Button>}
                    {donationProps.DonationLoader && <Spin><Button type="primary" htmlType="button" className="gx-pt-0" block>
                        Donate
                                </Button></Spin>}
                </Form.Item>
                <div className="secure">
                    <p>Secure payment powered by stripe</p>
                    <div className="imagesS">
                        <img src={img1} alt="" />
                        <img src={img2} alt="" />
                        <img src={img3} alt="" />
                    </div>
                    <p>
                        By continuing, you are agree with ub4i terms and privacy policy, and understand that your donation is non-refundable
                    </p>
                </div>
            </Form>
        </div>
    )
}

export default DonationForm

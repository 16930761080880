import _ from 'lodash';
const INIT_STATE = {
    TotalRevenue: 0,
    TotalRestaurant: 0,
    TotalCareCenter: 0,
    TotalTip: 0,
    TodayBalance: 0,
    TotalBalance: 0,
    TotalRevenueRH: 0,
    BalanceHistory: [],
    TotalRevenueOr: 0,
    totalOrderAmount: 0,
    completedOrderAmount: 0,
    inProgressOrderAmount: 0

};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'getRestaurantDashboardData_SUCCESS': {
            const Record = action.payload.Record?action.payload.Record:{completedOrderAmount:0, inProgressOrderAmount:0}
            return {
                ...state,
                totalOrderAmount: parseInt(Record.completedOrderAmount) + parseInt(Record.inProgressOrderAmount),
                completedOrderAmount: Record.completedOrderAmount,
                inProgressOrderAmount: Record.inProgressOrderAmount
            };
        }
        case 'totalOrder_SUCCESS': {
            return { ...state, TotalRevenueOr: action.payload.Record[0] && action.payload.Record[0].TotalAmount };
        }
        case 'totalRevenue_SUCCESS': {
            console.log("================ action",action);
            return { ...state, TotalRevenueRH: action.payload.Record[0] && action.payload.Record[0].TotalAmount };
        }

        case 'totalUserCount_SUCCESS': {
            const totalRestaurant = getTotalFor('restaurant', action.payload.Record);
            const totalCareCenter = getTotalFor('careCenter', action.payload.Record);
            return { ...state, TotalRestaurant: totalRestaurant, TotalCareCenter: totalCareCenter };
        }
        case 'totalBalance_SUCCESS': {
            const tBalance = action.payload.Record[0]
            return { ...state, TotalBalance: tBalance?tBalance.balance:0, TotalTip: tBalance?tBalance.tip:0 };
        }
        case 'todayBalance_SUCCESS': {
            return { ...state, TodayBalance: action.payload.Record ? action.payload.Record.totalAmount : 0 };
        }
        case 'balanceHistory_SUCCESS': {
            const data = BalanceHistoryData(action.payload.Record);
            return { ...state, BalanceHistory: data };
        }


        default:
            return state;
    }
}

function getTotalFor(type, data) {
    const index = _.findIndex(data, x => x._id === type);
    if (index > -1) {
        const total = data[index].total;
        return total;
    }else {
        return 0;
    }

}


function BalanceHistoryData(data) {
    const dataMonth = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
    data.map(Item => {
        Item.name = dataMonth[parseInt(Item._id)];
        return Item;
    })
    return data
}
import React, { useEffect } from 'react'
import Organizer from 'components/Organizer'
import { useDispatch, useSelector } from 'react-redux';
import { DataGetAction } from 'redux/actions/http'

const SideBarCard = ({ title, data }) => {

    const dispatch = useDispatch();
    const ResProps = useSelector(state => state.user.hospitals);

    const fetchPublicProfile = () => {
        dispatch(DataGetAction('getPublicProfileForRh', "Rh"));
    }

    useEffect(fetchPublicProfile, []);


   
    const renderCareCenter = () => {
        if (ResProps) {
            return ResProps.map(Item => {
                return <Organizer shape="square" color="#F03E3D" name={Item.name} title={Item.location} />

            })
        }
    }

    return (
        <div className="SideBarCard gx-p-4 gx-mb-3">
            <h3 className="gx-font-weight-bold gx-mb-4">{title}</h3>
            <div className="list">
                {renderCareCenter()}
            </div>
        </div>
    )
}

export default SideBarCard

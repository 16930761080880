import React from "react";
import Widget from 'components/Widget'
import { Button, message } from 'antd'
import { useDispatch } from 'react-redux'
import { AddToCart } from 'redux/actions/Common'
import { useSelector } from 'react-redux';
const ProductItem = ({ product, restaurantName, restaurantId, availableBalance, restaurantStatus, updatedRestaurantId }) => {

  const { imageUrl = "https://via.placeholder.com/600x400", title, price, description } = product;
  const dispatch = useDispatch();
  const cartProps = useSelector(state => state.cart);

  const addProductToCart = (product) => {

    const availableBalanceNow = parseInt(availableBalance) - parseInt(product.price);
    dispatch({
      type: 'recentBalance_SUCCESS',
      payload: { Record: [{ balance: availableBalanceNow }] }
    })

    if (cartProps.cartQty > 0) {
      if (cartProps.Cart.restaurantId === restaurantId) {
        dispatch(AddToCart(product, restaurantName, restaurantId, "plus"))
        message.success("Successfully add Item in your cart")
      } else {
        message.info("Place New Order/Clear your cart for change the restaurant")
      }
    } else {
      dispatch(AddToCart(product, restaurantName, restaurantId, "plus"))
      message.success("Successfully add Item in your cart")
    }
  }

  const renderAddToCartButton = () => {
      if (restaurantStatus) {
        return <Button disabled={parseInt(price) > parseInt(availableBalance) ? true : false} size="small" type="primary" htmlType="button" onClick={() => addProductToCart(product)} className="gx-pt-0">Add to Cart</Button>
      } else {
        return <Button disabled={true} size="small" type="primary" htmlType="button" onClick={() => addProductToCart(product)} className="gx-pt-0">Add to Cart</Button>
      }
  }

  return (
    <Widget styleName="productItemWidget">
      <div className={`gx-product-item  gx-product-horizontal`}>
        <div className="gx-product-image">
          <div className="gx-grid-thumb-equal">
            <span className="gx-link gx-grid-thumb-cover">
              <img alt="Remy Sharp" src={imageUrl} />
            </span>
          </div>
        </div>

        <div className="gx-product-body gx-pointer">
          <h3 className="gx-product-title gx-mb-0">{title} </h3>
          <p className="gx-mt-2 gx-mb-3" style={{ color: '#848484', fontSize: '13px' }}>{description}</p>
          <div className="gx-d-flex gx-flex-row  gx-justify-content-between">
            <span>${price}</span>
            {renderAddToCartButton()}
          </div>
        </div>
      </div>
    </Widget>
  )
};

export default ProductItem;


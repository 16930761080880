import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from './Home'
import RestaurantAdmin from './Restaurant/Portal/index'
import CategoryRestaurant from './Restaurant/Portal/category'
import ProductsRestaurant from './Restaurant/Portal/products'
import TransactionsRestaurant from './transactions'
import Orders from './orders'
import Category from './Admin/category'
import FrontPage from './frontPage'

import Restaurant from './CareCenter/Portal/Restaurant'
import Products from './CareCenter/Portal/Products'
import CareCenterAdmin from './CareCenter/Portal'
import SettingsRestaurant from './Restaurant/Portal/settings'


import AdminDashboard from './Admin'
import Users from './Admin/user'
import Donation from './Admin/donations'
import Settings from './settings'
import RestPassword from './restPassword'

const App = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}restaurant/dashboard`} component={RestaurantAdmin} />
    <Route exact path={`${match.url}restaurant/category`} component={CategoryRestaurant} />
    <Route exact path={`${match.url}restaurant/products`} component={ProductsRestaurant} />
    <Route exact path={`${match.url}restaurant/orders`} component={Orders} />
    <Route exact path={`${match.url}restaurant/transactions`} component={TransactionsRestaurant} />
    <Route exact path={`${match.url}restaurant/frontPage`} component={FrontPage} />
    <Route exact path={`${match.url}restaurant/settings`} component={SettingsRestaurant} />

    <Route exact path={`${match.url}careCenter/dashboard`} component={CareCenterAdmin} />
    <Route exact path={`${match.url}careCenter/restaurants`} component={Restaurant} />
    <Route exact path={`${match.url}careCenter/products`} component={Products} />
    <Route exact path={`${match.url}careCenter/orders`} component={Orders} />
    <Route exact path={`${match.url}careCenter/frontPage`} component={FrontPage} />

    <Route path={`${match.url}admin/dashboard`} component={AdminDashboard} />
    <Route path={`${match.url}admin/restaurants`} component={Users} />
    <Route path={`${match.url}admin/careCenter`} component={Users} />
    <Route path={`${match.url}admin/donations`} component={Donation} />
    <Route path={`${match.url}admin/transactions`} component={TransactionsRestaurant} />
    <Route path={`${match.url}admin/settings`} component={Settings} />
    <Route path={`${match.url}admin/orders`} component={Orders} />
    <Route path={`${match.url}admin/category`} component={Category} />
   
    <Route path={`${match.url}admin/restPassword`} component={RestPassword} />
    <Route path={`${match.url}careCenter/restPassword`} component={RestPassword} />
    <Route path={`${match.url}restaurant/restPassword`} component={RestPassword} />

    <Route path={`${match.url}home`} component={Home} />
  </Switch>
);

export default App;


const INIT_STATE = {
    Loading:false,

};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'Search_START': {
            console.log("========= Call ========");
            return { ...state, Loading: !state.Loading };
        }
        case 'Search_STOP': {
            return { ...state, Loading: !state.Loading };
        }
        
        default:
            return state;
    }
}

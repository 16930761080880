import React, { useEffect } from 'react'
import Layout from 'components/LayoutForPortal'
import { Row, Col } from 'antd'
import { Area, AreaChart, Line, LineChart, ResponsiveContainer, Tooltip } from "recharts";
import { recentActivity, increamentData } from "./data";
import { useDispatch, useSelector } from 'react-redux';
import { DataGetAction } from 'redux/actions/http';
import Widget from "components/Widget/index";
import ChartCard from "components/Dashboard/ChartCard";
import Portfolio from "components/Dashboard/Portfolio";
import BalanceHistory from "components/Dashboard/BalanceHistory";
import RecentActivity from "components/Dashboard/RecentActivity";


const Dashboard = () => {

    const donation = useSelector(state => state.donation);
    const user = useSelector(state => state.user);
    const dashboard = useSelector(state => state.dashboard);

    const dispatch = useDispatch();

    const fetchDashboardData = () => {
        if (user.signUpSuccess) {
            dispatch(DataGetAction('totalUserCount', ''));
        }
        if (donation.success) {
            dispatch(DataGetAction('totalBalance', ''));
            dispatch(DataGetAction('todayBalance', ''));
            dispatch(DataGetAction('balanceHistory', ''));
        }
    }

    useEffect(fetchDashboardData, [user.signUpSuccess, donation.success])

    const fetchDashboardDataDidMount = () => {
        dispatch(DataGetAction('totalUserCount', ''));
        dispatch(DataGetAction('totalBalance', ''));
        dispatch(DataGetAction('todayBalance', ''));
        dispatch(DataGetAction('balanceHistory', ''));
    }

    useEffect(fetchDashboardDataDidMount, [])



    return (
        <Layout>
            <Row>
                <Col xl={6} lg={12} md={12} sm={12} xs={24}>
                    <ChartCard prize={`$${dashboard.TodayBalance}`} icon="bitcoin"
                        children={<ResponsiveContainer width="100%" height={75}>
                            <AreaChart data={increamentData}
                                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                <Tooltip />
                                <defs>
                                    <linearGradient id="color3" x1="0" y1="0" x2="1" y2="0">
                                        <stop offset="5%" stopColor="#163469" stopOpacity={0.9} />
                                        <stop offset="95%" stopColor="#FE9E15" stopOpacity={0.9} />
                                    </linearGradient>
                                </defs>
                                <Area dataKey='price' strokeWidth={0} stackId="2" stroke='#4D95F3' fill="url(#color3)"
                                    fillOpacity={1} />
                            </AreaChart>
                        </ResponsiveContainer>}
                        styleName="up" desc="Today Donation" />
                </Col>
                <Col xl={6} lg={12} md={12} sm={12} xs={24}>
                    <ChartCard prize={`$${dashboard.TotalTip}`} icon="etherium"
                        children={<ResponsiveContainer width="100%" height={75}>
                            <AreaChart data={increamentData}
                                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                <Tooltip />
                                <defs>
                                    <linearGradient id="color4" x1="0" y1="0" x2="1" y2="0">
                                        <stop offset="5%" stopColor="#4ECDE4" stopOpacity={0.9} />
                                        <stop offset="95%" stopColor="#06BB8A" stopOpacity={0.9} />
                                    </linearGradient>
                                </defs>
                                <Area dataKey='price' type='monotone' strokeWidth={0} stackId="2" stroke='#4D95F3'
                                    fill="url(#color4)"
                                    fillOpacity={1} />
                            </AreaChart>
                        </ResponsiveContainer>}
                        styleName="up" desc="Total Tip" />
                </Col>
                <Col xl={6} lg={12} md={12} sm={12} xs={24}>
                    <ChartCard prize={dashboard.TotalRestaurant} icon="ripple"
                        children={<ResponsiveContainer width="100%" height={75}>
                            <AreaChart data={[]}
                                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                <Tooltip />
                                <defs>
                                    <linearGradient id="color5" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#e81a24" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#FEEADA" stopOpacity={0.8} />
                                    </linearGradient>
                                </defs>
                                <Area dataKey='price' strokeWidth={0} stackId="2" stroke='#FEEADA' fill="url(#color5)"
                                    fillOpacity={1} />
                            </AreaChart>
                        </ResponsiveContainer>}
                        styleName="down" desc="Total Restaurants" />
                </Col>
                <Col xl={6} lg={12} md={12} sm={12} xs={24}>
                    <ChartCard prize={dashboard.TotalCareCenter} icon="litcoin"
                        children={<ResponsiveContainer width="100%" height={75}>

                            <LineChart data={[]}
                                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                                <Tooltip />
                                <Line dataKey="price" stroke="#038FDE" dot={{ stroke: '#FEA931', strokeWidth: 2 }} />
                            </LineChart>
                        </ResponsiveContainer>}
                        styleName="down" desc="Total Care Centers" />
                </Col>
                <Col xl={12} lg={24} md={12} sm={24} xs={24}>
                    <Portfolio TotalBalance={dashboard.TotalBalance} />
                </Col>
                <Col xl={12} lg={24} md={12} sm={24} xs={24}>
                    <BalanceHistory history={dashboard.BalanceHistory} />
                </Col>
            </Row>
            <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24} >
                    <Widget>
                        <RecentActivity recentList={recentActivity} shape="circle" />
                    </Widget>

                </Col>
            </Row>
        </Layout>
    )
}

export default Dashboard

import React from 'react'
import Organizer from 'components/Organizer'
const index = ({ name, date, desc }) => {
    return (
        <div className="commentItem gx-pt-3 gx-mb-2">
            <Organizer color="#E0374F" name={name} title={date} />
            <p>{desc}</p>
        </div>
    )
}

export default index
